import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TreeSceneNode,
  StructurePanel,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  StructurePanelConfig,
} from 'components/common/structure-panel.mjs';

import cadex from '@cadexchanger/web-toolkit';

/**
 * @typedef SGETreeItem
 * @property {string} [text]
 * @property {string} [type]
 * @property {Object} [state]
 * @property {boolean} [state.opened]
 * @property {Object} data
 * @property {cadex.ModelData_SceneGraphElement} [data.element]
 * @property {cadex.ModelPrs_SceneNode} data.sceneNode
 * @property {Record<string, Array<SGETreeItem>>} [typedChildren]
 * @property {Array<SGETreeItem>} children
 */

class SceneGraphConverter extends cadex.ModelData_SceneGraphElementVisitor {
  /**
   * @param {any} theJsTree
   * @param {string} theRootNodeId
   * @param {cadex.ModelData_RepresentationMask} theRepMask
   * @param {cadex.ModelPrs_SceneNode} theNode
   */
  constructor (theJsTree, theRootNodeId, theRepMask, theNode) {
    super();
    this.jstree = theJsTree;
    this.treeNodes = [theRootNodeId];
    this.sceneNodes = [theNode];
    /** @type {cadex.ModelData_Instance|null} */
    this.lastInstance = null;
    this.sceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
    this.repMask = theRepMask;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  currentTreeNode () {
    return this.treeNodes[this.treeNodes.length - 1];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  currentSceneNode () {
    return this.sceneNodes[this.sceneNodes.length - 1];
  }

  /**
   * @param {!cadex.ModelData_SceneGraphElement} theElement
   * @param {boolean} theAddToStack
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  addSceneNode (theElement, theAddToStack) {
    const aSceneNode = this.sceneNodeFactory.createNodeFromSceneGraphElement(theElement);
    this.currentSceneNode().addChildNode(aSceneNode);
    if (theAddToStack) {
      this.sceneNodes.push(aSceneNode);
    }
    return aSceneNode;
  }

  /**
   * @override
   * @param {!cadex.ModelData_Part} thePart
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitPart (thePart) {
    const anInstanceNode = this.lastInstance && this.currentSceneNode();
    const aPartNode = this.addSceneNode(thePart, false);
    let aRepresentationNode;
    const aRepresentation = thePart.representation(this.repMask);
    if (aRepresentation) {
      aRepresentationNode = this.sceneNodeFactory.createNodeFromRepresentation(aRepresentation);
      aPartNode.addChildNode(aRepresentationNode);
    }

    const aSceneNode = anInstanceNode || aPartNode;

    /** @type {SGETreeItem} */
    const aTreeItem = {
      text: this.lastInstance?.name || thePart.name || 'Unnamed Part',
      type: 'part',
      data: {
        element: this.lastInstance || thePart,
        sceneNode: aSceneNode,
      },
      children: [],
    };

    const aNodeId = this.jstree.create_node(this.currentTreeNode(), aTreeItem);
    this.jstree.loading_node(aNodeId);

    /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(aSceneNode).treeId = aNodeId;
    if (aRepresentationNode) {
      /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(aRepresentationNode).treeId = aNodeId;
    }

    const aGeometry = aRepresentationNode?.geometry;
    if (aGeometry) {
      aGeometry.addEventListener('stateChanged', () => {
        switch (aGeometry.state) {
          case cadex.ModelPrs_GeometryState.Loading:
            this.jstree.loading_node(aNodeId);
            break;
          case cadex.ModelPrs_GeometryState.Completed:
            this.jstree.display_node(aNodeId);
            break;
          case cadex.ModelPrs_GeometryState.Failed:
            this.jstree.error_node(aNodeId);
            break;
          default:
            break;
        }
      });
    }
  }

  /**
   * @override
   * @param {!cadex.ModelData_Instance} theInstance
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitInstanceEnter (theInstance) {
    this.lastInstance = theInstance;
    this.addSceneNode(theInstance, true);
    return true;
  }

  /**
   * @override
   * @param {!cadex.ModelData_Instance} _theInstance
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitInstanceLeave (_theInstance) {
    this.lastInstance = null;
    this.sceneNodes.pop();
  }

  /**
   * @override
   * @param {!cadex.ModelData_Assembly} theAssembly
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitAssemblyEnter (theAssembly) {
    const anInstanceNode = this.lastInstance && this.currentSceneNode();
    const anAssemblyNode = this.addSceneNode(theAssembly, true);
    const aSceneNode = anInstanceNode || anAssemblyNode;

    /** @type {SGETreeItem} */
    const aTreeItem = {
      text: this.lastInstance?.name || theAssembly.name || 'Unnamed Assembly',
      type: 'assembly',
      state: {
        opened: this.treeNodes.length === 1, // open root assemblies
      },
      data: {
        element: this.lastInstance || theAssembly,
        sceneNode: aSceneNode,
      },
      children: [],
    };

    const aNodeId = this.jstree.create_node(this.currentTreeNode(), aTreeItem);
    /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(aSceneNode).treeId = aNodeId;
    this.treeNodes.push(aNodeId);

    return true;
  }

  /**
   * @override
   * @param {!cadex.ModelData_Assembly} _theAssembly
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitAssemblyLeave (_theAssembly) {
    this.treeNodes.pop();
    this.sceneNodes.pop();
  }
}

/**
 * @typedef {Omit<StructurePanelConfig, "types">}
 * @type {any}
 */
export let MCADStructurePanelConfig;

/**
 * @type {Partial<MCADStructurePanelConfig>}
 */
export const MCADStructurePanelDefaultConfig = {
  title: 'Structure',
};

export class MCADStructurePanel extends StructurePanel {
  /**
   * @param {MCADStructurePanelConfig} theConfig
   */
  constructor (theConfig) {
    const aConfig = /** @type {Required<StructurePanelConfig>} */(Object.assign({
      types: {
        file: {
          icon: 'icon-file',
        },
        assembly: {
          icon: 'icon-assembly',
        },
        instance: {
          icon: 'icon-instance',
        },
        part: {
          icon: 'icon-part',
        },
      },
    }, MCADStructurePanelDefaultConfig, theConfig));
    super(aConfig);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectedSceneGraphElements () {
    const anElements = [];
    for (const aSelectedItem of this.scene.selectionManager.selectedItems()) {
      const aSelectedNode = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(aSelectedItem.node);
      if (aSelectedNode.treeId) {
        const aNode = /** @type {SGETreeItem} */(this.jstree.get_node(aSelectedNode.treeId));
        if (aNode.data.element) {
          anElements.push(aNode.data.element);
        }
      }
    }
    return anElements;
  }

  /**
   * @param {cadex.ModelData_Model} theModel
   * @param {string} theModelName
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadModel (theModel, theModelName) {
    // Create root file item
    const aFileNodeId = this.jstree.create_node('#', {
      text: theModelName,
      type: 'file',
      data: {},
    });

    const aVisitor = new SceneGraphConverter(this.jstree, aFileNodeId, this.representationMask, this.modelSceneNode);
    await theModel.accept(aVisitor);

    this.jstree.open_node(aFileNodeId);
  }

  /**
   * @override
   * @protected
   * @param {Object} theJstreeNode
   * @returns {Array<Object>}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  collectGeometryJstreeNodes (theJstreeNode) {
    // @ts-expect-error need to declare JstreeNode type
    if (theJstreeNode.type === 'part') {
      return [theJstreeNode];
    }
    else {
      // @ts-expect-error need to declare JstreeNode type
      return theJstreeNode.children_d.reduce((thePartNodes, theChildId) => {
        const aChild = this.jstree.get_node(theChildId);
        if (aChild.type === 'part') {
          thePartNodes.push(aChild);
        }
        return thePartNodes;
      }, []);
    }
  }
}
