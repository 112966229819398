import { htmlToElement } from 'components/common/dom.mjs';
import {
  ViewerTools,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ViewerToolsConfig,
} from 'components/common/viewer-tools.mjs';

export class DrawingViewerTools extends ViewerTools {
  /**
   * @param {ViewerToolsConfig} theConfig
   */
  constructor (theConfig) {
    const aDrawingDefaultConfig = {
      showStructureButton: true,
      displayOptionsConfig: {
        showDisplayMode: false,
        showCameraProjectionType: false,
      },
      showExplode: false,
      showPropertiesButton: true,
    };
    const aConfig = Object.assign({}, aDrawingDefaultConfig, theConfig);
    aConfig.displayOptionsConfig = Object.assign(aDrawingDefaultConfig.displayOptionsConfig, theConfig.displayOptionsConfig);

    super(aConfig);

    /** @private */
    this._layersButtonActive = false;

    this._layersButton = /** @type {HTMLButtonElement} */(htmlToElement(
      `<button type="button" title="Layers" class="viewer-tools__button drawing-viewer-tools__layers-button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M 23.417 11.993 L 12 17.954 L 0.584 11.993 M 23.417 8.019 L 12 13.98 L 0.584 8.019 L 12 2.059 L 23.417 8.019 Z M 23.416 15.979 L 11.999 21.94 L 0.583 15.979" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" style=""></path>
        </svg>
      </button>`,
    ));
    this._layersButton.addEventListener('click', () => {
      this.layersButtonActive = !this.layersButtonActive;
    });

    this.domElement.insertBefore(this._layersButton, this._structureButton ? this._structureButton.nextSibling : this.domElement.firstChild);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get layersButtonActive () {
    return this._layersButtonActive;
  }

  set layersButtonActive (theActive) {
    if (this._layersButtonActive !== theActive) {
      this._layersButton?.classList.toggle('viewer-tools__button_active', !this._layersButtonActive);
      this._layersButtonActive = theActive;
      this.dispatchEvent({ type: 'layersButtonActiveChanged' });
    }
  }
}
