/* eslint-disable @typescript-eslint/restrict-template-expressions */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PropertiesPanel, PropertiesPanelConfig } from 'components/common/properties-panel.mjs';
import { htmlToElement } from 'components/common/dom.mjs';

import cadex from '@cadexchanger/web-toolkit';

const aTmpColor = new cadex.ModelData_ColorObject();

class DrawingPropertiesExtractor extends cadex.ModelData_DrawingElementVisitor {
  constructor () {
    super();
    /**
     * @type {cadex.ModelData_DrawingView|undefined}
     */
    this.view = undefined;
    this.angleUnits = cadex.Base_AngleUnit.Base_AU_Degrees;
    this.lengthUnits = cadex.Base_LengthUnit.Base_LU_Millimeters;

    /** @type {Array<Record<string, (string|number|null|undefined)>>} */
    this.generalProperties = [];
    /** @type {Array<Record<string, (string|number|null|undefined)>>} */
    this.appearanceProperties = [];
  }

  /**
   * @private
   * @param {cadex.ModelData_DrawingElement} theElement
   * @param {string} theType
   * @param {Record<string, string|number|boolean|null|undefined>} [theExtraProperties]
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingElement (theElement, theType, theExtraProperties) {
    const aGenericProperties = {
      Name: theElement.name,
      Type: theType,
    };
    if (theExtraProperties) {
      Object.assign(aGenericProperties, theExtraProperties);
    }
    this.generalProperties.push(aGenericProperties);

    const anAppearance = this.view?.elementAppearance(theElement);
    if (anAppearance?.toColor(aTmpColor)) {
      const aColorHex = aTmpColor.getHex().toString(16).padStart(6, '0').toUpperCase();
      const aProperties = {
        Color: `#${aColorHex} <div class="properties-panel__property-color-box" style="background-color: #${aColorHex}"></div>`,
        Opacity: `${Math.round(aTmpColor.a * 100)}%`,
      };
      if (anAppearance.lineProperties) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        aProperties['Line Type'] = PropertiesPanel.enumValue(cadex.ModelData_LineType, anAppearance.lineProperties.type);
      }
      this.appearanceProperties.push(aProperties);
    }
  }

  /**
   * @private
   * @param {cadex.ModelData_DrawingDimension} theElement
   * @param {string} theType
   * @param {Record<string, string|number|boolean|null|undefined>} [theExtraProperties]
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingDimension (theElement, theType, theExtraProperties) {
    this.visitDrawingElement(theElement, theType, Object.assign({
      Value: `${(theElement.measurementValue / cadex.Base_Helper.lengthUnitCoef(this.lengthUnits)).toFixed(3)}${cadex.Base_Helper.lengthUnitSymbol(this.lengthUnits)}`,
      'Align Text Horizontally': `${theElement.alignTextHorizontally}`,
      'Arrowhead Placement': `${PropertiesPanel.enumValue(cadex.ModelData_DrawingDimensionArrowheadPlacementType, theElement.arrowheadPlacement)}`,
    }, theExtraProperties));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingAngularDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingAngularDimension (theElement) {
    this.visitDrawingDimension(theElement, 'Angular Dimension', {
      Value: `${(theElement.measurementValue / cadex.Base_Helper.angleUnitCoef(this.angleUnits)).toFixed(2)}${cadex.Base_Helper.angleUnitSymbol(this.angleUnits)}`,
    });
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingDiametricDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingDiametricDimension (theElement) {
    this.visitDrawingDimension(theElement, 'Diametric Dimension', {
      'Start From Center': theElement.startsFromCenter,
    });
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingLinearDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingLinearDimension (theElement) {
    this.visitDrawingDimension(theElement, 'Linear Dimension');
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingRadialDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingRadialDimension (theElement) {
    this.visitDrawingDimension(theElement, 'Radial Dimension');
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingCurveSet} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingCurveSet (theElement) {
    this.visitDrawingElement(theElement, 'Curve Set');
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingPiecewiseContour} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingPiecewiseContour (theElement) {
    this.visitDrawingElement(theElement, 'Piecewise Contour');
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingPointSet} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingPointSet (theElement) {
    this.visitDrawingElement(theElement, 'Point Set', {
      'Number of points': `${theElement.numberOfPoints}`,
    });
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingHatch} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingHatch (theElement) {
    this.visitDrawingElement(theElement, 'Hatch', {
      'Number of contours': `${theElement.numberOfContours}`,
      Style: PropertiesPanel.enumValue(cadex.ModelData_DrawingHatchStyleType, theElement.style),
      Pattern: PropertiesPanel.enumValue(cadex.ModelData_DrawingHatchPatternType, theElement.pattern),
    });
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingText} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingText (theElement) {
    this.visitDrawingElement(theElement, 'Text', {
      Text: theElement.text,
      'Font size': theElement.textProperties.fontSize,
      Rotation: theElement.textProperties.rotation,
      Justification: PropertiesPanel.enumValue(cadex.ModelData_TextJustificationType, theElement.textProperties.justification),
    });
  }
}

export class DrawingPropertiesPanel extends PropertiesPanel {
  /**
   * @param {PropertiesPanelConfig} theConfig
   */
  constructor (theConfig) {
    super(theConfig);

    /**
     * @private
     * @type {Array<{view: cadex.ModelData_DrawingView, element: cadex.ModelData_DrawingElement}>}
     */
    this._selectedElements = [];

    this.hide();
  }

  /**
   * @param {Array<{view: cadex.ModelData_DrawingView, element: cadex.ModelData_DrawingElement}>} theElements
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadElements (theElements) {
    this._selectedElements = theElements;
    if (this.isShown) {
      await this.update();
    }
  }

  /**
   * @protected
   * @override
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async update () {
    this._panelBody.replaceChildren();
    if (this._selectedElements.length === 0) {
      this._panelBody.appendChild(htmlToElement('<div class="properties-panel__empty-label">Select an element</div>'));
      return;
    }

    const aPropertiesExtractor = new DrawingPropertiesExtractor();
    for (const anElement of this._selectedElements) {
      aPropertiesExtractor.view = anElement.view;
      anElement.element.accept(aPropertiesExtractor);
    }

    const aGeneralProperties = aPropertiesExtractor.generalProperties;
    if (aGeneralProperties.length > 1) {
      const anUniqueNames = Array.from(new Set(aGeneralProperties.map(theProps => theProps.Name)));
      const anUniqueTypes = Array.from(new Set(aGeneralProperties.map(theProps => theProps.Type)));
      aGeneralProperties[0] = {
        Name: anUniqueNames.length > 1 ? 'n/a' : anUniqueNames[0],
        Type: anUniqueTypes.length > 1 ? 'n/a' : anUniqueTypes[0],
      };
    }

    const aGeneralPropertiesGroup = this.createPropertiesGroup('General', aGeneralProperties[0]);
    this._panelBody.appendChild(aGeneralPropertiesGroup);

    const anAppearanceProperties = aPropertiesExtractor.appearanceProperties;
    if (aGeneralProperties.length === 1 && anAppearanceProperties.length === 1) {
      const aMaterialPropertiesGroup = this.createPropertiesGroup('Appearance', anAppearanceProperties[0]);
      this._panelBody.appendChild(aMaterialPropertiesGroup);
    }
  }
}
