import cadex from '@cadexchanger/web-toolkit';

import { BasePanel } from './base-panel.mjs';

import $ from 'jquery';
import 'jstree';

import './jstree.sgestates.js';

import './structure-panel.css';
import './jstree.sgestates.css';

/**
 * Interface for additional 'treeId' property.
 * @typedef {{treeId: ?string;}}
 * @type {any}
 */
export let TreeSceneNode;

/**
 * @typedef {{
 *   scene: cadex.ModelPrs_Scene;
 *   modelSceneNode: cadex.ModelPrs_SceneNode;
 *   domElement: HTMLElement;
 *   title?: string;
 *   types: Record<string, {icon: string;}>;
 *   multiSelection?: boolean;
 * }}
 * @type {any}
 */
export let StructurePanelConfig;

/**
 * @type {Partial<StructurePanelConfig>}
 */
export const StructurePanelDefaultConfig = {
  title: 'Structure',
  types: {},
  multiSelection: true,
};

export class StructurePanel extends BasePanel {
  /**
   * @param {StructurePanelConfig} theConfig
   */
  constructor (theConfig) {
    const aConfig = /** @type {Required<StructurePanelConfig>} */(Object.assign({}, StructurePanelDefaultConfig, theConfig));
    super(aConfig);

    this.scene = theConfig.scene;
    this.modelSceneNode = theConfig.modelSceneNode;
    this.representationMask = cadex.ModelData_RepresentationMask.ModelData_RM_BRep;

    this.domElement.classList.add('structure-panel');

    const aJSTreeConfig = {
      core: {
        multiple: aConfig.multiSelection,
        check_callback: true,
        themes: {
          name: null, // 'default',
          dots: true,
        },
      },
      types: aConfig.types,
      plugins: ['wholerow', 'types', 'sgestates'],
    };

    this._panelBody.classList.add('structure-panel__tree');
    // Initialize jsTree library used for visualizing scenegraph structure (see https://www.jstree.com/)
    $(this._panelBody).jstree(aJSTreeConfig)
      .on('changed.jstree', (/** @type {string | JQuery.Event} */ _theEvent, /** @type {{ selected: string[]; }} */ theData) => {
        this.onSelectionChangedByTreeView(theData.selected);
      })
      .on('activate_node.jstree', async (/** @type {any} */ _theEvent, /** @type {{ node: Object; displayed: boolean | undefined; }} */ theData) => {
        await this.onDisplayedChangedByTreeView(theData.node, theData.displayed);
      });

    this.jstree = $(this._panelBody).jstree(true);

    // Subscribe to selection events
    this.scene.selectionManager.addEventListener('selectionChanged', this.onSelectionChangedByScene.bind(this));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clear () {
    const aRootNode = this.jstree.get_node('#');
    this.jstree.delete_node(aRootNode.children);
  }

  /**
   * @private
   * @param {cadex.ModelPrs_SelectionChangedEvent} theEvent
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSelectionChangedByScene (theEvent) {
    theEvent.added.forEach(theAdded => {
      const anAddedObject = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(theAdded.node);
      if (anAddedObject.treeId) {
        this.jstree.select_node(anAddedObject.treeId, true);
      }
    });
    theEvent.removed.forEach(theRemoved => {
      const aRemovedObject = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(theRemoved.node);
      if (aRemovedObject.treeId) {
        this.jstree.deselect_node(aRemovedObject.treeId, true);
      }
    });
    // Handle deselectAll on click on empty space on the viewer
    if (this.scene.selectionManager.numberOfSelectedItems === 0) {
      this.jstree.deselect_all(true);
    }
    // Manually notify jsTree plugins about selection change to apply tree styles.
    this.jstree.trigger('changed', {
      action: 'select_by_scene',
      selected: this.jstree.get_selected(),
    });
  }

  /**
   * @param {string[]} theNodeIds
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  onSelectionChangedByTreeView (theNodeIds) {
    this.scene.selectionManager.deselectAll(false);
    theNodeIds.forEach((theNodeId) => {
      const aJstreeNode = this.jstree.get_node(theNodeId);
      this.collectGeometryJstreeNodes(aJstreeNode).forEach(thePartJstreeNode => {
        // @ts-expect-error need to declare JstreeNode type
        const aSceneNode = /** @type {cadex.ModelPrs_SceneNode|undefined} */(thePartJstreeNode.data.sceneNode);
        if (aSceneNode) {
          this.scene.selectionManager.selectNode(aSceneNode, /* theBreakSelection */false, /* theDispatchEvent */false);
        }
      });
    });
    this.dispatchEvent({ type: 'selectionChanged' });
  }

  /**
   * @private
   * @param {Object} theJstreeNode
   * @param {boolean|undefined} theDisplayed
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async onDisplayedChangedByTreeView (theJstreeNode, theDisplayed) {
    if (theDisplayed === undefined) {
      return;
    }
    this.collectGeometryJstreeNodes(theJstreeNode).forEach(theGeometryJstreeNode => {
      // @ts-expect-error need to declare JstreeNode type
      if (theGeometryJstreeNode.data.sceneNode) {
        // @ts-expect-error need to declare JstreeNode type
        theGeometryJstreeNode.data.sceneNode.visibilityMode = theDisplayed ? cadex.ModelPrs_VisibilityMode.Visible : cadex.ModelPrs_VisibilityMode.Hidden;
      }
    });
    await this.scene.update();
  }

  /**
   * Collects nodes with geometries to select/display
   * @protected
   * @param {Object} _theJstreeNode
   * @returns {Array<Object>}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  collectGeometryJstreeNodes (_theJstreeNode) {
    return [];
  }
}
