import { BasePanel } from './base-panel.mjs';
import { htmlToElement } from './dom.mjs';

import './properties-panel.css';

export let /** @type {{domElement: HTMLElement, title?: string }} */ PropertiesPanelConfig;

/** @type {Partial<PropertiesPanelConfig>} */
export const PropertiesPanelDefaultConfig = {
  title: 'Properties',
};

export class PropertiesPanel extends BasePanel {
  /**
   * @param {PropertiesPanelConfig} theConfig
   */
  constructor (theConfig) {
    const aConfig = /** @type {Required<PropertiesPanelConfig>} */(Object.assign({}, PropertiesPanelDefaultConfig, theConfig));
    super(aConfig);

    this.domElement.classList.add('properties-panel');
    this._panelTitle.classList.add('properties-panel__title');
    this._panelBody.classList.add('properties-panel__body');
  }

  /**
   * @override
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  show () {
    this.update();
    super.show();
  }

  /** @protected */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  update () {
    this._panelBody.replaceChildren();
    this._panelBody.appendChild(htmlToElement('<div class="properties-panel__empty-label">Select an element</div>'));
  }

  /**
   * @param {string} theGroupName
   * @param {Record<string, (string|number|Date|cadex.ModelData_Point|cadex.ModelData_Box|null|undefined)>} theProperties
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createPropertiesGroup (theGroupName, theProperties) {
    const aGroupBody = Object.keys(theProperties).map((theName) =>
      `<div class="properties-panel__property">
        <div class="properties-panel__property-name">${theName}</div>
        <div class="properties-panel__property-value">${String(theProperties[theName])}</div>
      </div>`).join('');

    const aGroup = htmlToElement(
      `<div class="properties-panel__group">
        <div class="properties-panel__group-body">
          ${aGroupBody}
        </div>
      </div>`);

    const anExpandButton = htmlToElement(`<button type="button" class="properties-panel__group-collapse-button">${theGroupName}</button>`);
    anExpandButton.addEventListener('click', () => {
      aGroup.classList.toggle('properties-panel__group_collapsed');
    });

    const aGroupTitle = htmlToElement('<h6 class="properties-panel__group-title"></h6>');
    aGroupTitle.appendChild(anExpandButton);
    aGroup.prepend(aGroupTitle);

    return aGroup;
  }

  /**
   * @template V
   * @param {Record<string, V>} theEnum
   * @param {V} theValue
   * @returns {string|undefined}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  static enumValue (theEnum, theValue) {
    return Object.keys(theEnum).find(v => theEnum[v] === theValue);
  }
}
