import cadex from '@cadexchanger/web-toolkit';

/* eslint-disable @typescript-eslint/no-empty-function */
class CancellationObserver extends cadex.Base_ProgressStatusObserver {
  /**
   * @param {XMLHttpRequest} xhr
   */
  constructor (xhr) {
    super();
    this.xhr = xhr;
  }

  /**
   * @override
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  changedValue () { }

  /**
   * @override
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  completed () { }

  /**
   * @override
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  canceled () {
    this.xhr.abort();
  }
}

/**
 * Remote file data provider.
 * @param {string} theUrl
 * @param {cadex.Base_ProgressScope} theProgressScope
 * @returns {Promise<ArrayBuffer>}
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export async function fetchFile (theUrl, theProgressScope) {
  const aFileDownloadingScope = new cadex.Base_ProgressScope(theProgressScope);
  /** @type {CancellationObserver|undefined} */
  let aProgressStatusCancelationObserver;
  return await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', theUrl, true);
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      }
      else {
        reject(new Error(xhr.statusText));
      }
    };
    xhr.onerror = () => {
      reject(new Error(xhr.statusText));
    };
    xhr.onprogress = (event) => {
      aFileDownloadingScope.increment(event.loaded - aFileDownloadingScope.value);
    };
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED && xhr.status === 200) {
        const fileSize = xhr.getResponseHeader('content-length');
        aFileDownloadingScope.setRange(0, Number(fileSize));
      }
    };

    aProgressStatusCancelationObserver = new CancellationObserver(xhr);
    aFileDownloadingScope.owner.register(aProgressStatusCancelationObserver);

    xhr.send();
  })
    .finally(() => {
      aFileDownloadingScope.close();
      if (aProgressStatusCancelationObserver) {
        aFileDownloadingScope.owner.unregister(aProgressStatusCancelationObserver);
      }
    });
}

export class ModelAnalyzer extends cadex.ModelData_SceneGraphElementVoidVisitor {
  constructor () {
    super();
    this.hasBRepRep = false;
    this.polyRepCount = 0;
  }

  /**
   * @param {cadex.ModelData_Part} thePart
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitPart (thePart) {
    const aHasBRepRep = Boolean(thePart.brepRepresentation());
    if (aHasBRepRep) {
      this.hasBRepRep = true;
    }
    this.polyRepCount = Math.max(this.polyRepCount, thePart.numberOfRepresentation - (aHasBRepRep ? 1 : 0));
  }
}
