import { makeAutoObservable } from 'mobx';

import helicopterMainGearboxWEBP from 'img/carousel/helicopter-main-gearbox.STEP.webp';
import helicopterMainGearboxX2WEBP from 'img/carousel/helicopter-main-gearbox-x2.STEP.webp';

import lamborghiniCentenarioWEBP from 'img/carousel/lamborghini-centenario.OBJ.webp';
import lamborghiniCentenarioX2WEBP from 'img/carousel/lamborghini-centenario-x2.OBJ.webp';

import mecanumWheelWEBP from 'img/carousel/mecanum-wheel.JT.webp';
import mecanumWheelX2WEBP from 'img/carousel/mecanum-wheel-x2.JT.webp';

import privateHouseWEBP from 'img/carousel/private-house.DWG.webp';
import privateHouseX2WEBP from 'img/carousel/private-house-x2.DWG.webp';

import superbikeWEBP from 'img/carousel/superbike.GLTF.webp';
import superbikeX2WEBP from 'img/carousel/superbike-x2.GLTF.webp';

import V2EngineWEBP from 'img/carousel/engine.PARASOLID.webp';
import V2EngineX2WEBP from 'img/carousel/engine-x2.PARASOLID.webp';

import v6EngineAssembleWEBP from 'img/carousel/v6-engine-assemble.CATIA.webp';
import v6EngineAssembleX2WEBP from 'img/carousel/v6-engine-assemble-x2.CATIA.webp';

import waterCoolerWEBP from 'img/carousel/water-cooler.SLDASM.webp';
import waterCoolerX2WEBP from 'img/carousel/water-cooler-x2.SLDASM.webp';

import nistWEBP from 'img/carousel/nist-ftc.STP.webp';
import nistX2WEBP from 'img/carousel/nist-ftc-x2.STP.webp';

interface Cards {
  img: string
  imgX2: string
  imgAlt: string
  title: string
  format: string
  extension: string
  size: string
  modelName: string
}

const cards: Cards[] = [
  {
    img: helicopterMainGearboxWEBP,
    imgX2: helicopterMainGearboxX2WEBP,
    imgAlt: 'Helicopter main gearbox',
    title: 'Helicopter main gearbox',
    format: 'STEP',
    extension: 'step',
    size: '116 MB',
    modelName: 'helicopter-main-gearbox',
  },
  {
    img: lamborghiniCentenarioWEBP,
    imgX2: lamborghiniCentenarioX2WEBP,
    imgAlt: 'Lamborghini Centenario',
    title: 'Lamborghini Centenario',
    format: 'OBJ',
    extension: 'obj',
    size: '56.7 MB',
    modelName: 'lamborghini-centenario',
  },
  {
    img: mecanumWheelWEBP,
    imgX2: mecanumWheelX2WEBP,
    imgAlt: 'Mecanum Wheel',
    title: 'Mecanum Wheel',
    format: 'JT',
    extension: 'stp',
    size: '1.7 MB',
    modelName: 'mecanum-wheel',
  },
  {
    img: privateHouseWEBP,
    imgX2: privateHouseX2WEBP,
    imgAlt: 'Private house',
    title: 'Private house',
    format: 'DWG',
    extension: 'dwg',
    size: '205 KB',
    modelName: 'private-house',
  },
  {
    img: superbikeWEBP,
    imgX2: superbikeX2WEBP,
    imgAlt: 'Superbike',
    title: 'Superbike',
    format: 'GLTF',
    extension: 'glb',
    size: '1.4 MB',
    modelName: 'superbike',
  },
  {
    img: V2EngineWEBP,
    imgX2: V2EngineX2WEBP,
    imgAlt: 'V2 engine',
    title: 'V2 engine',
    format: 'Parasolid',
    extension: 'x_t',
    size: '24.9 MB',
    modelName: 'engine-v-twin-4-valve',
  },
  {
    img: v6EngineAssembleWEBP,
    imgX2: v6EngineAssembleX2WEBP,
    imgAlt: 'V6 Engine assemble',
    title: 'V6 Engine assemble',
    format: 'CATIA',
    extension: 'catpart',
    size: '811 KB',
    modelName: 'v6-engine-assemble',
  },
  {
    img: waterCoolerWEBP,
    imgX2: waterCoolerX2WEBP,
    imgAlt: 'Water cooler',
    title: 'Water cooler',
    format: 'SLDPRT',
    extension: 'step',
    size: '1.4 MB',
    modelName: 'pc-water-cooler',
  },
  {
    img: nistWEBP,
    imgX2: nistX2WEBP,
    imgAlt: 'NIST FTC 8',
    title: 'NIST FTC 8',
    format: 'STP',
    extension: 'stp',
    size: '1.04 MB',
    modelName: 'nist-ftc',
  },
];

export default class SamplesStore {
  private readonly _cards = cards;
  private _isMouseDown = false;
  private _translateX = 0;
  private _isTranslated = false;
  private _isTouch = 'ontouchstart' in window || !!navigator.maxTouchPoints;

  get cards (): Cards[] {
    return this._cards;
  }

  get isMouseDown (): boolean {
    return this._isMouseDown;
  }

  get translateX (): number {
    return this._translateX;
  }

  get isTranslated (): boolean {
    return this._isTranslated;
  }

  get isTouch (): boolean {
    return this._isTouch;
  }

  constructor () {
    makeAutoObservable(this);
  }

  setIsMouseDown (isMouseDown: boolean): void {
    this._isMouseDown = isMouseDown;
  }

  setTranslateX (translateX: number): void {
    this._translateX = translateX;
  }

  setIsTranslated (isTranslated: boolean): void {
    this._isTranslated = isTranslated;
  }

  setIsTouch (isTouch: boolean): void {
    this._isTouch = isTouch;
  }
}
