export const isEqualFiles = (firstFile: File, secondFile: File): boolean => {
  if (firstFile === null && secondFile !== null) {
    return false;
  }
  if (firstFile !== null && secondFile === null) {
    return false;
  }
  if (firstFile === null && secondFile === null) {
    return true;
  }
  return firstFile.name === secondFile.name && firstFile.size === secondFile.size && firstFile.type === secondFile.type;
};
