import type React from 'react';
import { useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AppStoreContext from 'contexts/AppStoreContext';

import { isEqualFiles } from 'utils/common';
import { supportedExtensions } from 'utils/checkFileExtension';

import './UploadFileBtn.scss';

import { ReactComponent as UploadFileBtnSVG } from 'img/upload-file-btn/upload-file-btn.svg';

interface UploadFileBtnProps {
  className?: string
  title?: string
  showIcon?: boolean
  disabled?: boolean
  onClick?: () => void
}

const UploadFileBtn: React.FC<UploadFileBtnProps> = ({
  className,
  title = 'Upload your file',
  showIcon = true,
  disabled,
  onClick,
}) => {
  const appStore = useContext(AppStoreContext);
  const history = useHistory();

  const inputButton = useRef<HTMLInputElement>(null);
  const inputAcceptExtensions = Object.values(supportedExtensions.import)
    .map((items) => items.map((item) => `.${item}`))
    .join(',');

  const handleUploadButtonClick = (): void => {
    inputButton.current?.click();
  };

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (event.target.files) {
      if (isEqualFiles(event.target.files[0], appStore.uploadFile)) {
        history.push(`/conversion/${appStore.uploadFileConversionID}`);
      }
      else {
        appStore.setUploadFile(event.target.files[0]);
      }
    }
  };

  return (
    <button
      className={`btn btn-primary d-flex flex-row align-items-center text-nowrap upload-file-btn ${className || ''}`}
      type="button"
      onClick={onClick || handleUploadButtonClick}
      disabled={disabled}
    >
      {showIcon && <UploadFileBtnSVG className="mr-2" />}
      { title }
      <input ref={inputButton} hidden onChange={handleSelectFile} type="file" accept={inputAcceptExtensions} />
    </button>
  );
};

export default observer(UploadFileBtn);
