// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PropertiesPanel, PropertiesPanelConfig } from 'components/common/properties-panel.mjs';
import { htmlToElement } from 'components/common/dom.mjs';

import cadex from '@cadexchanger/web-toolkit';

class BIMElementPropertiesExtractor extends cadex.ModelData_BIMElementVisitor {
  constructor () {
    super();
    /** @type {Array<Record<string, (string|number|!Date|!cadex.ModelData_Point|!cadex.ModelData_Box|null|undefined)>>} */
    this.generalProperties = [];
    /** @type {Array<Record<string, (string|number|!Date|!cadex.ModelData_Point|!cadex.ModelData_Box|null|undefined)>>} */
    this.materialProperties = [];
    /** @type {Array<Array<cadex.ModelData_PropertyTable>>} */
    this.customProperties = [];
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMBeam} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitBeam (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMBeamSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Beam', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMColumn} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitColumn (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMColumnSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Column', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMDoor} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitDoor (theElement) {
    const anExtraProperties = { Operation: PropertiesPanel.enumValue(cadex.ModelData_BIMDoorOperationType, theElement.operationType) };
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMDoorSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Door', aSubtype, anExtraProperties);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMFurniture} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitFurniture (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMFurnitureSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Furniture', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMPlate} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitPlate (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMPlateSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Plate', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMRailing} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitRailing (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMRailingSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Railing', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMRoof} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitRoof (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMRoofSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Roof', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSlab} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitSlab (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMSlabSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Slab', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMStair} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitStair (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMStairSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Stair', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMWall} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitWall (theElement) {
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMWallSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Wall', aSubtype);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMWindow} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitWindow (theElement) {
    const anExtraProperties = { Partitioning: PropertiesPanel.enumValue(cadex.ModelData_BIMWindowPartitioningType, theElement.partitioningType) };
    const aSubtype = PropertiesPanel.enumValue(cadex.ModelData_BIMWindowSubType, theElement.subType);
    await this.visitBIMGeometryElement(theElement, 'Window', aSubtype, anExtraProperties);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMCustomGeometryElement} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitCustomGeometryElement (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Custom', theElement.type);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMBuilding} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBuildingEnter (theElement) {
    return this.visitBIMHostElementEnter(theElement, 'Building');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMBuilding} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBuildingLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSite} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSiteEnter (theElement) {
    const anExtraProperties = { Elevation: theElement.elevation };
    return this.visitBIMHostElementEnter(theElement, 'Site', anExtraProperties);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSite} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSiteLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMStorey} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitStoreyEnter (theElement) {
    const anExtraProperties = { Elevation: theElement.elevation };
    return this.visitBIMHostElementEnter(theElement, 'Storey', anExtraProperties);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMStorey} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitStoreyLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSpace} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSpaceEnter (theElement) {
    const anExtraProperties = {
      Subtype: theElement.subType,
      'Floor Elevation': theElement.floorElevation,
    };
    return this.visitBIMHostElementEnter(theElement, 'Space', anExtraProperties);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSpace} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSpaceLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @package
   * @param {!cadex.ModelData_BIMGeometryElement} theElement
   * @param {string} theTypeName
   * @param {string|undefined} theSubtype
   * @param {Record<string, string|number|null|undefined>} [theExtraProperties]
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitBIMGeometryElement (theElement, theTypeName, theSubtype, theExtraProperties) {
    const aGenericProperties = {
      Name: theElement.name,
      Type: theTypeName,
      Subtype: theSubtype,
    };
    if (theExtraProperties) {
      Object.assign(aGenericProperties, theExtraProperties);
    }
    this.generalProperties.push(aGenericProperties);

    if (theElement.material) {
      const aMaterialProperties = {
        'Material Name': theElement.material.name || '&lt;undefined&gt;',
        Category: theElement.material.category || '&lt;undefined&gt;',
        Color: '',
      };

      if (theElement.material.appearance) {
        const aColor = new cadex.ModelData_ColorObject();
        if (theElement.material.appearance.toColor(aColor)) {
          const aColorHex = aColor.getHex().toString(16).padStart(6, '0').toUpperCase();
          aMaterialProperties.Color = `#${aColorHex} <div class="properties-panel__property-color-box" style="background-color: #${aColorHex}"></div>`;
        }
      }
      this.materialProperties.push(aMaterialProperties);
    }

    this.customProperties.push([...theElement.properties.tables()]);
  }

  /**
   * @private
   * @param {!cadex.ModelData_BIMHostElement} theElement
   * @param {string} theTypeName
   * @param {Record<string, string|number|null|undefined>} [theExtraProperties]
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBIMHostElementEnter (theElement, theTypeName, theExtraProperties) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.generalProperties['Name'] = theElement.name;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.generalProperties['Type'] = theTypeName;
    if (theExtraProperties) {
      Object.assign(this.generalProperties, theExtraProperties);
    }
    this.customProperties.push([...theElement.properties.tables()]);
    return false;
  }

  /**
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBIMHostElementLeave () {
  }
}

export class BIMPropertiesPanel extends PropertiesPanel {
  /**
   * @param {PropertiesPanelConfig} theConfig
   */
  constructor (theConfig) {
    super(theConfig);

    /**
     * @private
     * @type {cadex.ModelData_BIMElement[]}
     */
    this._selectedBIMElements = [];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async clear () {
    this._selectedBIMElements.length = 0;
    void this.update();
  }

  /**
   * @param {cadex.ModelData_BIMElement[]} theElements
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadBIMElements (theElements) {
    this._selectedBIMElements = theElements;
    if (this.isShown) {
      await this.update();
    }
  }

  /**
   * @protected
   * @override
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async update () {
    this._panelBody.replaceChildren();
    if (this._selectedBIMElements.length === 0) {
      this._panelBody.appendChild(htmlToElement('<div class="properties-panel__empty-label">Select an element</div>'));
      return;
    }

    const anUnits = htmlToElement(
      `<div class="properties-panel__units">
        <div class="properties-panel__property-name">Units:</div>
      </div>`,
    );
    const anUnitsSelector = /** @type {HTMLSelectElement} */(htmlToElement(
      // Units changing is not supported yet, so select is disabled
      `<select title="Units" class="properties-panel__units-selector properties-panel__property-value" disabled>
        <option value="${cadex.Base_LengthUnit.Base_LU_Millimeters}" selected>Millimeters</option>
        <option value="${cadex.Base_LengthUnit.Base_LU_Centimeters}">Centimeters</option>
        <option value="${cadex.Base_LengthUnit.Base_LU_Meters}">Meters</option>
        <option value="${cadex.Base_LengthUnit.Base_LU_Inches}">Inches</option>
        <option value="${cadex.Base_LengthUnit.Base_LU_Feets}">Feets</option>
        <option value="${cadex.Base_LengthUnit.Base_LU_Yards}">Yards</option>
      </select>`));
    anUnits.appendChild(anUnitsSelector);
    this._panelBody.appendChild(anUnits);

    const aPropertiesExtractor = new BIMElementPropertiesExtractor();
    for (const anElement of this._selectedBIMElements) {
      await anElement.accept(aPropertiesExtractor);
    }

    const aGeneralProperties = aPropertiesExtractor.generalProperties;
    if (aGeneralProperties.length > 1) {
      const anUniqueNames = Array.from(new Set(aGeneralProperties.map(theProps => theProps.Name)));
      const anUniqueTypes = Array.from(new Set(aGeneralProperties.map(theProps => theProps.Type)));
      const anUniqueSubTypes = Array.from(new Set(aGeneralProperties.map(theProps => theProps.Subtype)));
      aGeneralProperties[0] = {
        Name: anUniqueNames.length > 1 ? 'n/a' : anUniqueNames[0],
        Type: anUniqueTypes.length > 1 ? 'n/a' : anUniqueTypes[0],
        Subtype: (anUniqueTypes.length > 1 && anUniqueSubTypes.length > 1) ? 'n/a' : anUniqueSubTypes[0],
      };
    }

    const aGeneralPropertiesGroup = this.createPropertiesGroup('General', aGeneralProperties[0]);
    this._panelBody.appendChild(aGeneralPropertiesGroup);

    const aMaterialProperties = aPropertiesExtractor.materialProperties;
    if (aGeneralProperties.length === 1 && aMaterialProperties.length === 1) {
      const aMaterialPropertiesGroup = this.createPropertiesGroup('Material', aMaterialProperties[0]);
      this._panelBody.appendChild(aMaterialPropertiesGroup);
    }

    const aCustomProperties = aPropertiesExtractor.customProperties;
    if (aCustomProperties.length > 1) {
      const aCommonPropTables = [];
      for (const aPropertyTable of aCustomProperties[0]) {
        if (aCustomProperties.every((theProps) => theProps.includes(aPropertyTable))) {
          aCommonPropTables.push(aPropertyTable);
        }
      }
      aCustomProperties[0] = aCommonPropTables;
    }

    if (aCustomProperties.length > 0) {
      for (const aPropertyTable of aCustomProperties[0]) {
        const aProperties = await aPropertyTable.properties();
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const aMaterialPropertiesGroup = this.createPropertiesGroup(`Custom: ${aPropertyTable.name}`, aProperties);
        this._panelBody.appendChild(aMaterialPropertiesGroup);
      }
    }
  }
}
