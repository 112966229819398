import type React from 'react';

import { ReactComponent as BulbOnSVG } from 'img/features/bulb-on.svg';
import { ReactComponent as DocWithCubeSVG } from 'img/features/doc-with-cube.svg';
import { ReactComponent as DocWithLockSVG } from 'img/features/doc-with-lock.svg';
import { ReactComponent as CadIndependentSVG } from 'img/features/cad-independent.svg';

import './FeaturesSection.scss';

interface FeaturesSectionProps {
  uploadFileBtn?: JSX.Element
}

const FeaturesSection: React.FunctionComponent<FeaturesSectionProps> = ({ uploadFileBtn }) => (
  <section id="features-section" className="py-8 py-md-10 features-section">
    <div className="container d-flex flex-column align-items-center">
      <h2 className="mb-9 text-center text-wrap">Why choose CAD Exchanger<br />online viewer?</h2>
      <div className="container-fluid p-0 mb-9 features-section-cards">
        <div className="row">
          <div className="col-xl-6 mb-7">
            <div className="card shadow-light" data-aos="fade-down-right">
              <BulbOnSVG />
              <div className="card-body px-0 pb-0">
                <h5 className="card-title">Free and easy-to-use</h5>
                <p className="card-text">
                  View your 3D files free of charge and with no installations needed. All the data processing is performed on our cloud servers, keeping your computer resources untouched.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6 mb-7">
            <div className="card h-100 shadow-light" data-aos="fade-down-left">
              <DocWithCubeSVG />
              <div className="card-body px-0 pb-0">
                <h5 className="card-title">Access to the product data</h5>
                <p className="card-text">
                  View assemblies and parts, precise B-Reps and polygonal meshes, colors, materials and textures, meta-data (names, IDs and user-defined properties) and PMI.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6 mb-7 mb-lg-7 mb-xl-0">
            <div className="card h-100 shadow-light" data-aos="fade-up-right">
              <DocWithLockSVG />
              <div className="card-body px-0 pb-0">
                <h5 className="card-title">Data security</h5>
                <p className="card-text">
                  Be sure of your data security  thanks to the SSL-secured file transfer and Microsoft Azure storage solution. Your data is automatically deleted as soon as you close the browser tab or upload another file.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card shadow-light" data-aos="fade-up-left">
              <CadIndependentSVG />
              <div className="card-body px-0 pb-0">
                <h5 className="card-title">Cross-platform support</h5>
                <p className="card-text">
                  CAD Exchanger viewer supports various operating systems (Windows, iOS, Linux, Android) and browsers (Chrome, Safari, FireFox). All you need is a desktop or mobile device with an Internet connection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        uploadFileBtn
      }
    </div>
  </section>
);

export default FeaturesSection;
