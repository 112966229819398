import { makeAutoObservable, reaction, runInAction } from 'mobx';

import { type ModelFilesBuffers } from 'utils/unzipCDXFB';
import isExtensionValid, { getExtension } from 'utils/checkFileExtension';
import { sendToConvert, checkConversionStatus } from 'utils/convertModelFile';

type UserDefinedModelData = ModelFilesBuffers | null;

export default class AppStore {
  private _isModelLoading = false;
  private _uploadFile = null as unknown as File;
  private _modelFileExtension = '';
  private _isExtensionValid = false;
  private _internalError = null as unknown as Error;
  private _conversionLoadingMessage = '';
  private _uploadFileConversionID = '';
  private _userDefinedModelData: UserDefinedModelData = null;

  get isModelLoading (): boolean {
    return this._isModelLoading;
  }

  get uploadFile (): File {
    return this._uploadFile;
  }

  get modelFileExtension (): string {
    return this._modelFileExtension;
  }

  get isExtensionValid (): boolean {
    return this._isExtensionValid;
  }

  get internalError (): Error {
    return this._internalError;
  }

  get conversionLoadingMessage (): string {
    return this._conversionLoadingMessage;
  }

  get uploadFileConversionID (): string {
    return this._uploadFileConversionID;
  }

  get userDefinedModelData (): UserDefinedModelData {
    return this._userDefinedModelData;
  }

  constructor () {
    makeAutoObservable(this);

    reaction(
      () => this.uploadFile,
      (file) => {
        if (this.isExtensionValid && this.uploadFile) {
          void (async () => {
            try {
              this.setConversionLoadingMessage('Uploading ...');
              const conversionID = await sendToConvert(file);

              this.setConversionLoadingMessage('Converting ...');
              await checkConversionStatus(conversionID);

              this.setConversionLoadingMessage(''); // Clear loading modal.

              this.setUploadFileConversionID(conversionID);
            }
            catch (e) {
              runInAction(() => {
                this.setConversionLoadingMessage(''); // Clear loading modal.
                this.setInternalError(e as Error);
                this.setUploadFile(null as unknown as File);
              });
            }
          })();
        }
      },
    );
  }

  setIsModelLoading (isLoading: boolean): void {
    this._isModelLoading = isLoading;
  }

  setUploadFile (file: File): void {
    this._uploadFile = file;
    this.setModelFileExtension(getExtension(file.name));
    this._isExtensionValid = isExtensionValid(file);
  }

  setModelFileExtension (extension: string): void {
    this._modelFileExtension = extension;
  }

  setInternalError (error: Error): void {
    // eslint-disable-next-line no-console
    if (error) console.error(error);
    this._internalError = error;
  }

  setConversionLoadingMessage (message: string): void {
    this._conversionLoadingMessage = message;
  }

  setUploadFileConversionID (conversionID: string): void {
    this._uploadFileConversionID = conversionID;
  }

  setUserDefinedModelData (data: UserDefinedModelData): void {
    this._userDefinedModelData = data;
  }
}
