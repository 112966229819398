import cadex from '@cadexchanger/web-toolkit';
import { BaseViewer } from 'components/common/base-viewer.mjs';
import { DrawingViewerTools } from './drawing-viewer-tools.mjs';
import { DrawingLayersPanel } from './drawing-layers-panel.mjs';
import { DrawingPropertiesPanel } from './drawing-properties-panel.mjs';
import { DrawingStructurePanel } from './drawing-structure-panel.mjs';

import 'components/common/common.css';

import './DrawingViewer.css';

export interface ModelInfo {
  modelName: string
  filename: string
}

class DrawingManager {
  _sceneNodes: Map<cadex.ModelData_DrawingElement, Map<cadex.ModelData_DrawingView, cadex.ModelPrs_SceneNode>>;
  blackWhiteNodes: cadex.ModelPrs_SceneNode[];
  drawing: cadex.ModelData_Drawing | undefined;

  constructor () {
    /**
     * Drawing sheets/views and drawing layers use different structure of drawing elements,
     * but on displaying the panels should refer to the actual scene nodes.
     *
     * @private
     */
    this._sceneNodes = new Map();
    /**
     * Stores black and white scene nodes to update it's color on theme change
     * @type {Array<cadex.ModelPrs_SceneNode>}
     */
    this.blackWhiteNodes = [];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clear () {
    this._sceneNodes.clear();
    this.blackWhiteNodes.length = 0;
    this.drawing = undefined;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadDrawing (theDrawing: cadex.ModelData_Drawing) {
    this.clear();
    this.drawing = theDrawing;

    const aSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();

    if (!this.drawing) {
      return;
    }
    // Generate scene nodes for all drawing elements to allow nodes sharing between structure and layer panels.
    for (const aSheet of this.drawing.sheets()) {
      for (const aView of aSheet.views()) {
        for (const aDrawingElement of aView.elements()) {
          let anElementSceneNodes = this._sceneNodes.get(aDrawingElement);
          if (!anElementSceneNodes) {
            anElementSceneNodes = new Map();
            this._sceneNodes.set(aDrawingElement, anElementSceneNodes);
          }

          let anElementSceneNode = anElementSceneNodes.get(aView);
          if (!anElementSceneNode) {
            anElementSceneNode = aSceneNodeFactory.createNodeFromDrawingElement(aDrawingElement);
            let anAppearance = aView.elementAppearance(aDrawingElement);
            const aGenericColor = anAppearance?.genericColor;
            // Compare with white and black colors ignoring alpha channel
            const anIsBlackWhiteColor = !aGenericColor || aGenericColor.getHex() === 0xffffff || aGenericColor.getHex() === 0x000000;
            if (anIsBlackWhiteColor) {
              this.blackWhiteNodes.push(anElementSceneNode);
              // Change color of the node to black
              anAppearance = anAppearance?.clone() || new cadex.ModelData_Appearance();
              anAppearance.genericColor = new cadex.ModelData_ColorObject(0, 0, 0, aGenericColor?.a || 1);
            }
            anElementSceneNode.appearance = anAppearance;
            anElementSceneNodes.set(aView, anElementSceneNode);
          }
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  getSceneNode (theView: cadex.ModelData_DrawingView, theElement: cadex.ModelData_DrawingElement): cadex.ModelPrs_SceneNode | undefined {
    const anElementSceneNodes = this._sceneNodes.get(theElement);
    return anElementSceneNodes?.get(theView);
  }

  getLayerSceneNodes (theLayer: cadex.ModelData_DrawingLayer): cadex.ModelPrs_SceneNode[] {
    const aNodes: cadex.ModelPrs_SceneNode[] = [];
    for (const anElement of theLayer.elements()) {
      const anElementSceneNodes = this._sceneNodes.get(anElement);
      anElementSceneNodes?.forEach((theSceneNode) => aNodes.push(theSceneNode));
    }
    return aNodes;
  }
}

class DrawingViewer extends BaseViewer {
  drawingManager: DrawingManager;
  viewerTools: DrawingViewerTools;
  structurePanel: DrawingStructurePanel;
  layersPanel: any;
  propertiesPanel: DrawingPropertiesPanel;

  constructor (container: HTMLElement, viewportConfig?: cadex.ModelPrs_ViewPortConfig) {
    super(container, Object.assign({ showViewCube: false }, viewportConfig));

    const zoomHandler = new cadex.ModelPrs_CameraZoomHandler(this.viewport);
    zoomHandler.wheelZoomGravityPoint = cadex.ModelPrs_CameraZoomHandlerGravityPoint.PointerPosition;
    this.viewport.inputManager.pushInputHandler(zoomHandler);
    const panHandler = new cadex.ModelPrs_CameraPanHandler(this.viewport);
    panHandler.addAcceptedPointerButton(cadex.ModelPrs_PointerButton.LeftButton, cadex.ModelPrs_KeyboardModifier.NoModifier);
    this.viewport.inputManager.pushInputHandler(panHandler);
    this.viewport.inputManager.pushInputHandler(new cadex.ModelPrs_SelectionHandler(this.viewport));

    this.viewport.camera.set(new cadex.ModelData_Point(0, 0, 0), new cadex.ModelData_Point(0, 0, -1), new cadex.ModelData_Direction(0, 1, 0));

    this.drawingManager = new DrawingManager();

    // Setup viewer tools panel
    this.viewerTools = new DrawingViewerTools({
      viewport: this.viewport,
    });

    // Create structure panel
    const structurePanelHTMLElement = document.createElement('div');
    this.container.appendChild(structurePanelHTMLElement);
    // Setup structure panel
    this.structurePanel = new DrawingStructurePanel({
      scene: this.scene,
      viewport: this.viewport,
      modelSceneNode: this.modelSceneNode,
      domElement: structurePanelHTMLElement,
      sceneNodeProvider: (theView, theElem) => this.drawingManager.getSceneNode(theView, theElem),
    });
    // Unselect structure button on panel close button click
    this.structurePanel.addEventListener('hide', () => {
      this.viewerTools.structureButtonActive = false;
    });

    // Create layers panel
    const layersPanelHTMLElement = document.createElement('div');
    this.container.appendChild(layersPanelHTMLElement);
    // Setup layers panel
    this.layersPanel = new DrawingLayersPanel({
      scene: this.scene,
      domElement: layersPanelHTMLElement,
      sceneNodesProvider: (theLayer) => this.drawingManager.getLayerSceneNodes(theLayer),
    });
    // Unselect layers button on panel close button click
    this.layersPanel.addEventListener('hide', () => {
      this.viewerTools.layersButtonActive = false;
    });

    // Create properties panel
    const propertiesPanelHTMLElement = document.createElement('div');
    this.container.appendChild(propertiesPanelHTMLElement);
    // Setup properties panel
    this.propertiesPanel = new DrawingPropertiesPanel({
      domElement: propertiesPanelHTMLElement,
    });
    // Unselect properties button on panel close button click
    this.propertiesPanel.addEventListener('hide', () => {
      this.viewerTools.propertiesButtonActive = false;
    });

    // Show/hide structure panel on button click
    this.viewerTools.addEventListener('structureButtonActiveChanged', () => {
      if (this.viewerTools.structureButtonActive) {
        this.structurePanel.show();
        this.layersPanel.hide();
      }
      else {
        this.structurePanel.hide();
      }
    });
    // Show/hide layers panel on button click
    this.viewerTools.addEventListener('layersButtonActiveChanged', () => {
      if (this.viewerTools.layersButtonActive) {
        this.structurePanel.hide();
        this.layersPanel.show();
      }
      else {
        this.layersPanel.hide();
      }
    });
    // Show/hide properties panel on button click
    this.viewerTools.addEventListener('propertiesButtonActiveChanged', () => {
      if (this.viewerTools.propertiesButtonActive) {
        this.propertiesPanel.show();
      }
      else {
        this.propertiesPanel.hide();
      }
    });

    // Update UI theme
    this.viewerTools.addEventListener('themeChanged', async () => {
      document.documentElement.dataset.theme = this.viewerTools.theme;
      void this.updateBlackWhiteNodesAppearance();
    });

    // Load properties of selected drawing elements.
    this.structurePanel.addEventListener('selectionChanged', async () => {
      await this.propertiesPanel.loadElements(this.structurePanel.selectedDrawingElements());
    });

    this.viewerTools.structureButtonActive = true;
    this.viewerTools.propertiesButtonActive = false;
  }

  async clear (progressScope: cadex.Base_ProgressScope): Promise<void> {
    this.drawingManager.clear();
    this.structurePanel.clear();
    this.layersPanel.clear();
    await super.clear(progressScope);
  }

  async loadAndDisplayModel (modelInfo: ModelInfo, dataLoader: cadex.ModelData_ExternalDataProvider, progressScope: cadex.Base_ProgressScope): Promise<void> {
    const newProgressScope = new cadex.Base_ProgressScope(progressScope);
    try {
      await this.clear(new cadex.Base_ProgressScope(newProgressScope, 1));
      await this.loadModel(modelInfo.filename, dataLoader, new cadex.Base_ProgressScope(newProgressScope, 5));

      const drawing = this.model.drawing;
      if (drawing) {
        this.drawingManager.loadDrawing(drawing);
        void this.structurePanel.loadDrawing(drawing);
        this.layersPanel.loadDrawing(drawing);
        void this.updateBlackWhiteNodesAppearance();
      }
      else {
        this.structurePanel.clear();
        this.layersPanel.clear();
        console.log(`No drawings found in the model ${modelInfo.modelName}`);
      }
    }
    catch (error) {
      console.error(`Unable to load and display model "${modelInfo.modelName}"`, error);
      alert(`Unable to load and display model "${modelInfo.modelName}" [${(error as Error).message}]`);
    }
    finally {
      newProgressScope.close();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async updateBlackWhiteNodesAppearance () {
    const aTargetColorHex = this.viewerTools.theme === 'dark' ? 0xffffff : 0x000000;
    this.drawingManager.blackWhiteNodes.forEach((theSceneNode) => {
      const appearance = theSceneNode.appearance;
      const color = appearance?.genericColor;
      color?.setHex(aTargetColorHex);
      // Trigger appearance changing
      theSceneNode.appearance = appearance;
    });
    await this.scene.update();
  }

  dispose (): void {
    this.viewport.domElement.removeChild(this.viewerTools.domElement);
    this.viewport.domElement.removeChild(this.structurePanel.domElement);
    this.viewport.domElement.removeChild(this.propertiesPanel.domElement);
    this.viewport.domElement.removeChild(this.layersPanel.domElement);
    super.dispose();
  }
}

export default DrawingViewer;
