import type React from 'react';
import { Modal } from 'react-bootstrap';

import { ReactComponent as CloseCrossSVG } from 'img/modal-error/close-cross.svg';
import { ReactComponent as InvalidFileSVG } from 'img/modal-error/invalid-file.svg';
import { ReactComponent as ExclamationTriangleSVG } from 'img/modal-error/exclamation-triangle.svg';

import './ModalError.scss';

interface ModalErrorProps {
  isShow: boolean
  formatError?: boolean
  internalError?: boolean
  modalTitle: string
  modalBody: string
  modalFooter: string
  modalFooterHREF?: string
  targetBlank?: boolean
  onAnchorButtonClick?: () => void
  onModalClose?: () => void
}

const ModalError: React.FC<ModalErrorProps> = ({
  isShow,
  formatError,
  internalError,
  modalTitle,
  modalBody,
  modalFooter,
  modalFooterHREF,
  targetBlank = true,
  onAnchorButtonClick,
  onModalClose,
}) => {
  const handleModalClose = (): void => {
    if (onModalClose) {
      onModalClose();
    }
  };

  return (
    <Modal
      className="px-0"
      dialogClassName="modal-error"
      centered
      show={isShow}
      onHide={handleModalClose}
      animation={false} /* Strict Mode error: https://github.com/react-bootstrap/react-bootstrap/issues/5075 */
    >
      <CloseCrossSVG
        className="modal-error-cross"
        onClick={handleModalClose}
        onKeyPress={handleModalClose}
        role="button"
        tabIndex={0}
      />
      { formatError &&
        <InvalidFileSVG className="d-block mx-auto mt-6 mt-md-8 mb-2 mb-md-4 modal-error-icon" /> }
      { internalError &&
        <ExclamationTriangleSVG className="d-block mx-auto mt-6 mt-md-8 mb-2 mb-md-4 modal-error-icon" /> }
      <h2 className="mb-2 mb-md-3 text-center modal-error-title">
        {modalTitle}
      </h2>
      <p className="mb-4 mb-md-5 text-center modal-error-body">
        {modalBody}
      </p>
      <div className="mb-6 mb-md-8 text-center modal-error-footer">
        <a
          className="btn btn-primary"
          href={modalFooterHREF}
          target={targetBlank ? '_blank' : undefined}
          rel="noreferrer"
          onClick={onAnchorButtonClick}
        >
          {modalFooter}
        </a>
      </div>
    </Modal>
  );
};

export default ModalError;
