import type React from 'react';
import { useContext, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';

// import ReactWTKViewer from '@cadexchanger/react-wtk-viewer';
import ReactWTKViewer from 'components/ReactWTKViewer';

import { LoadingModal } from '@cadexchanger/react-component-library';

import AppStoreContext from 'contexts/AppStoreContext';

import { getConversionResult, getModelFileExtension } from 'utils/convertModelFile';
import unzipCDXFB, { dataProviderForJSZip } from 'utils/unzipCDXFB';
import dataProviderForSamples from 'utils/dataProviderForSamples';

import ViewerNavbar from 'components/ViewerNavbar';
import ModalError from 'components/ModalError';

import './ViewerPage.scss';

interface RouteParams {
  conversionID: string
  sampleName: string
}

const ViewerPage: React.FC = () => {
  const appStore = useContext(AppStoreContext);
  const history = useHistory();

  const { conversionID } = useParams<RouteParams>();
  const { sampleName } = useParams<RouteParams>();

  const targetModelID = conversionID || sampleName;

  const navBarRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<HTMLDivElement>(null);

  const brandNavBar = (
    <ViewerNavbar
      innerRef={navBarRef}
    />
  );

  const isCustomModel = conversionID && appStore.userDefinedModelData;
  const dataProvider = isCustomModel
    ? dataProviderForJSZip(appStore.userDefinedModelData)
    : dataProviderForSamples(targetModelID);

  const viewer = sampleName || (conversionID && appStore.userDefinedModelData)
    ? (
      <ReactWTKViewer
        innerRef={viewerRef}
        modelName={sampleName || appStore.uploadFile?.name || targetModelID}
        dataProvider={dataProvider}
        nativeExtension={(appStore.modelFileExtension === 'ifc' || appStore.modelFileExtension === 'rvt') ? 'cdxbimweb' : 'cdxweb'}
        isPotentialDrawing={appStore.modelFileExtension === 'dwg'}
      />
      )
    : null;

  const downloadingModal = (
    <LoadingModal
      message={appStore.conversionLoadingMessage}
      isShown={!!appStore.conversionLoadingMessage}
      onHide={() => {
        appStore.setConversionLoadingMessage('');
      }}
    />
  );

  const internalError = (
    <ModalError
      isShow={!!appStore.internalError}
      internalError
      modalTitle="An internal error occurred"
      modalBody={appStore.internalError ? appStore.internalError.message : 'Unable to process the model.\nPlease report the problem via to contact form.'}
      modalFooter="Report the problem"
      modalFooterHREF="https://cadexchanger.com/contact-us/technical-report/"
      onModalClose={() => {
        appStore.setInternalError(null as unknown as Error);
        history.replace('/');
      }}
    />
  );

  useEffect(() => {
    void (async () => {
      if (conversionID) {
        try {
          appStore.setConversionLoadingMessage('Downloading ...');

          const conversionResult = await getConversionResult(conversionID);
          const modelFileExtension = await getModelFileExtension(conversionID);
          appStore.setModelFileExtension(modelFileExtension);

          const unzippedCDXFBData = await unzipCDXFB(conversionResult);

          runInAction(() => {
            appStore.setUserDefinedModelData(unzippedCDXFBData);
            appStore.setConversionLoadingMessage(''); // Clear loading modal.
          });
        }
        catch (e) {
          runInAction(() => {
            appStore.setConversionLoadingMessage(''); // Clear loading modal.
            appStore.setInternalError(e as Error);
            appStore.setUploadFile(null as unknown as File);
          });
        }
      }
    })();
  }, [targetModelID]);

  useEffect(() => {
    /* Fix viewer-container height relative to navBar height: */
    if (navBarRef.current && viewerRef.current) {
      const navBarHeight = window.getComputedStyle(navBarRef.current).height;
      (viewerRef.current.style).height = `calc(100vh - ${navBarHeight})`;
    }
    else if (viewerRef.current) {
      (viewerRef.current.style).height = '100vh';
    }
  });

  useEffect(() => {
    const hideNavBarHamburgerMenuEvent = new Event('ov-hide-nav-hamburger-menu');
    const hideNavBarHamburgerMenu = (): void => {
      document.dispatchEvent(hideNavBarHamburgerMenuEvent);
    };
    viewerRef.current?.addEventListener('click', hideNavBarHamburgerMenu);
    return () => {
      viewerRef.current?.removeEventListener('click', hideNavBarHamburgerMenu);
    };
  });

  return (
    <>

      { brandNavBar }

      { viewer }

      { downloadingModal }

      { internalError }

    </>
  );
};

export default observer(ViewerPage);
