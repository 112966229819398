import type React from 'react';
import { useContext, useRef } from 'react';
import { observer } from 'mobx-react-lite';

import { Footer as FooterSection, BrandNavBar, AnchorLink, LoadingModal } from '@cadexchanger/react-component-library';

import AppStoreContext from 'contexts/AppStoreContext';

import AOS from 'components/AOS';
import UploadFileBtn from 'components/UploadFileBtn';
import Dropzone from 'components/Dropzone';
import HeaderSection from 'components/HeaderSection';

import SamplesSection from 'components/SamplesSection';
import SamplesStoreContext from 'contexts/SamplesStoreContext';
import SamplesStore from 'stores/SamplesStore';

import FeaturesSection from 'components/FeaturesSection';
// import AdvancedSection from 'components/AdvancedSection';
import LearnMoreSection from 'components/LearnMoreSection';
import ModalError from 'components/ModalError';

import './HomePage.scss';

import motorcycleModelWEBP from 'img/dropzone/motorcycle-model.webp';
import motorcycleModelX2WEBP from 'img/dropzone/motorcycle-model-x2.webp';

const HomePage: React.FC = observer(() => {
  const appStore = useContext(AppStoreContext);

  const samplesSectionRef = useRef<HTMLDivElement>(null);

  const uploadFileBtn = (
    <UploadFileBtn />
  );

  const dropzone = (
    <Dropzone
      uploadFileBtn={uploadFileBtn}
      modelImg={motorcycleModelWEBP}
      modelImgX2={motorcycleModelX2WEBP}
      modelImgAlt="CAD Exchanger viewer model."
    />
  );

  const fileFormatError = (
    <ModalError
      isShow={appStore.uploadFile === null ? false : !appStore.isExtensionValid}
      formatError
      modalTitle="Invalid file format"
      modalBody={'Only files supported by CAD Exchanger are allowed.\nView the complete list of extensions.'}
      modalFooter="View extensions"
      modalFooterHREF="https://cadexchanger.com/formats"
      onModalClose={() => {
        appStore.setUploadFile(null as unknown as File);
      }}
    />
  );

  const internalError = (
    <ModalError
      isShow={!!appStore.internalError}
      internalError
      modalTitle="An internal error occurred"
      modalBody={'Unable to process the model.\nPlease report the problem via to contact form.'}
      modalFooter="Report the problem"
      modalFooterHREF="https://cadexchanger.com/contact-us/technical-report/"
      onModalClose={() => {
        appStore.setUploadFile(null as unknown as File);
        appStore.setInternalError(null as unknown as Error);
      }}
    />
  );

  const loadingModal = (
    <LoadingModal
      message={appStore.conversionLoadingMessage}
      isShown={!!appStore.conversionLoadingMessage}
      onHide={() => {
        appStore.setConversionLoadingMessage('');
      }}
    />
  );

  return (
    <>
      <BrandNavBar
        links={[
          { content: 'Overview', to: '#overview-section', as: AnchorLink },
          { content: 'Samples', to: '#samples-section', as: AnchorLink },
          { content: 'Features', to: '#features-section', as: AnchorLink },
          // { content: 'Advanced', to: '#advanced-section', as: AnchorLink },
        ]}
        collapseOverflowHidden
      />

      <HeaderSection
        rightSidePanel={dropzone}
      />

      <AOS>
        <SamplesStoreContext.Provider value={new SamplesStore()}>
          <SamplesSection
            innerRef={samplesSectionRef}
            uploadFileBtn={uploadFileBtn}
          />
        </SamplesStoreContext.Provider>

        <FeaturesSection uploadFileBtn={uploadFileBtn} />

        {/* <AdvancedSection /> */}

        <LearnMoreSection />

        <FooterSection>
          <a href="https://cadexchanger.com/contact-us" className="mt-5">Contact us</a>
        </FooterSection>

        {fileFormatError}
        {internalError}

        {loadingModal}
      </AOS>
    </>
  );
});

export default HomePage;
