import type React from 'react';
import { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import ReactGA from 'react-ga';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';

import AppStoreContext from 'contexts/AppStoreContext';

import HomePage from 'pages/HomePage';
import ViewerPage from 'pages/ViewerPage';

const TRACKING_ID = 'UA-62964543-4';
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  const appStore = useContext(AppStoreContext);

  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  /* Set up and remove reaction: */
  useEffect(() => {
    const dispose = reaction(
      () => appStore.uploadFileConversionID,
      (conversionID) => {
        history.push(`/conversion/${conversionID}`);
      },
    );

    return () => {
      dispose();
    };
  }, []);

  return (
    <>
      <Switch>
      <Route exact path="/" component={HomePage} />
        <Route
          path={['/conversion/:conversionID', '/samples/:sampleName']}
          component={ViewerPage}
        />
        {/* Otherwise, by default if all of the above paths do not match: */}
        <Redirect to="/" />
      </Switch>
    </>
  );
};

export default observer(App);
