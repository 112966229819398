import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import AppStoreContext from 'contexts/AppStoreContext';
import AppStore from 'stores/AppStore';
import App from './App';

/* General styles for the project from common library: (including Bootstrap, LandKit and common customizations) */
import '@cadexchanger/react-component-library/styles/theme.scss';
/* Importing styles for all common components: */
import '@cadexchanger/react-component-library/styles/components.scss';
/* FOV global style and customizations: */
import 'styles/global.scss';

const baseUrl = document
  .getElementsByTagName('base')[0]
  .getAttribute('href') as string;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={baseUrl}>
      <AppStoreContext.Provider value={new AppStore()}>
        <App />
      </AppStoreContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
