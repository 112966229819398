import type cadex from '@cadexchanger/web-toolkit';
import { fetchFile } from 'components/common/helpers.mjs';

const dataProviderForSamples = (sampleFolderName: string): cadex.ModelData_ExternalDataProvider => {
  const getNativeFile = async (fileName: string, progressScope: cadex.Base_ProgressScope): Promise<ArrayBuffer> => {
    const url = `/data/samples/${sampleFolderName}/${fileName}`;

    return await fetchFile(url, progressScope);

    // class CancellationObserver extends cadex.Base_ProgressStatusObserver {
    //   public xhr;
    //   constructor(xhr: XMLHttpRequest) {
    //     super();
    //     this.xhr = xhr;
    //   }
    //   changedValue() {}
    //   completed() {}
    //   canceled() {
    //     this.xhr.abort();
    //   }
    // }

    // const fileDownloadingScope = new cadex.Base_ProgressScope(progressScope);

    // let progressStatusCancelationObserver: CancellationObserver | null = null;

    // let fileData: ArrayBuffer;

    // try {
    //   const xhr = new XMLHttpRequest();
    //   xhr.responseType = 'arraybuffer';
    //   xhr.open('GET', url, true);
    //   xhr.onload = () => {
    //     if (xhr.status >= 200 && xhr.status < 300) {
    //       fileData = xhr.response;
    //     } else {
    //       throw new Error(xhr.statusText);
    //     }
    //   };
    //   xhr.onerror = () => {
    //     throw new Error(xhr.statusText);
    //   };
    //   xhr.onprogress = (event) => {
    //     fileDownloadingScope.increment(event.loaded - fileDownloadingScope.value);
    //   };
    //   xhr.onreadystatechange = () => {
    //     if (xhr.readyState === xhr.HEADERS_RECEIVED && xhr.status === 200) {
    //       const fileSize = xhr.getResponseHeader('content-length');
    //       fileDownloadingScope.setRange(0, Number(fileSize));
    //     }
    //   };

    //   progressStatusCancelationObserver = new CancellationObserver(xhr);
    //   fileDownloadingScope.owner.register(progressStatusCancelationObserver);

    //   xhr.send();
    // }
    // catch (error) {
    //   if (error instanceof Error) {
    //     throw new Error(error.message);
    //   }
    //   throw error;
    // }
    // finally {
    //   fileDownloadingScope.close();
    //   if (progressStatusCancelationObserver) {
    //     fileDownloadingScope.owner.unregister(progressStatusCancelationObserver);
    //   }
    // }

    // return fileData;
  };

  return getNativeFile;
};

export default dataProviderForSamples;
