import cadex from '@cadexchanger/web-toolkit';

import { ModelAnalyzer } from './helpers.mjs';

export let /** @type {{modelName: string, filename: string}} */ ModelInfo;

export class BaseViewer {
  /**
   * @param {HTMLElement} theContainer
   * @param {cadex.ModelPrs_ViewPortConfig} [theViewPortConfig]
   */
  constructor (theContainer, theViewPortConfig) {
    this.container = theContainer;
    this.container.id = 'cadex-wtk-viewer';
    this.container.classList.add('cadex-wtk-viewer');
    // The models
    this.model = new cadex.ModelData_Model();
    this.bimModel = new cadex.ModelData_BIMModel();
    // The scene for visualization
    this.scene = new cadex.ModelPrs_Scene();
    // The scene node for model visualization
    this.modelSceneNode = new cadex.ModelPrs_SceneNode();
    this.modelSceneNode.selectionMode = cadex.ModelPrs_SelectionMode.Body | cadex.ModelPrs_SelectionMode.PolyShape;
    this.scene.addRoot(this.modelSceneNode);

    // The viewport for visualization. Initializing with default config and element attach to.
    this.viewport = new cadex.ModelPrs_ViewPort(theViewPortConfig, this.container);
    // Attach viewport to scene to render content of
    this.viewport.attachToScene(this.scene);

    /** @type {cadex.ModelPrs_ViewportPaddings|number} */
    this.fitAllMargins = 5;

    this.hasBRepRep = false;
    this.polyRepCount = 0;
    this.disposed = false;
  }

  /**
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async clear (theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    this.model.clear();
    this.modelSceneNode.removeChildNodes();
    await this.scene.update(theProgressScope);
  }

  /**
   * @param {string} theFileName
   * @param {cadex.ModelData_ExternalDataProvider} dataLoader
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadModel (theFileName, dataLoader, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    const aModelLoadingProgressScope = new cadex.Base_ProgressScope(theProgressScope);
    try {
      // Load model using universal reader.
      const aModelReader = new cadex.ModelData_ModelReader(aModelLoadingProgressScope);
      if (!await aModelReader.loadModel(theFileName, this.model, dataLoader)) {
        throw new Error(`Failed to load and convert the file ${theFileName}`);
      }

      const aModelAnalyser = new ModelAnalyzer();
      const anUniqueVisitor = new cadex.ModelData_SceneGraphElementUniqueVisitor(aModelAnalyser);
      await this.model.accept(anUniqueVisitor);
      this.hasBRepRep = aModelAnalyser.hasBRepRep;
      this.polyRepCount = aModelAnalyser.polyRepCount;

      console.log(`Model '${theFileName}' is loaded\n`);
    }
    finally {
      aModelLoadingProgressScope.close();
    }
  }

  /**
   * @param {string} theFileName
   * @param {cadex.ModelData_ExternalDataProvider} dataLoader
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadBIMModel (theFileName, dataLoader, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    const aModelLoadingProgressScope = new cadex.Base_ProgressScope(theProgressScope);
    try {
      // Load model using universal reader.
      const aModelReader = new cadex.ModelData_ModelReader(aModelLoadingProgressScope);
      if (!await aModelReader.loadBIMModel(theFileName, this.bimModel, dataLoader)) {
        throw new Error(`Failed to load and convert the file ${theFileName}`);
      }
      this.hasBRepRep = true;
      this.polyRepCount = 0;
      console.log(`Model '${theFileName}' is loaded\n`);
    }
    finally {
      aModelLoadingProgressScope.close();
    }
  }

  /**
   * @param {cadex.ModelData_RepresentationMask} theRepMask
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async displayModel (theRepMask, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    const aModelDisplayingProgressScope = new cadex.Base_ProgressScope(theProgressScope);
    try {
      // Create visualization graph for model.
      const aSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();

      const aSceneNode = await aSceneNodeFactory.createGraphFromModel(this.model, theRepMask);
      if (!aSceneNode) {
        throw new Error('Failed to create scene graph from the model.');
      }

      this.modelSceneNode.addChildNode(aSceneNode);

      // Update scene to apply changes.
      await this.updateSceneSmoothly(aModelDisplayingProgressScope);

      // Finally move camera to position when the whole model is in sight
      this.viewport.fitAll(this.fitAllMargins);
    }
    finally {
      aModelDisplayingProgressScope.close();
    }
  }

  /**
   * @param {cadex.ModelData_RepresentationMask} theRepMask
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async displayBIMModel (theRepMask, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    const aModelDisplayingProgressScope = new cadex.Base_ProgressScope(theProgressScope);
    try {
      // Create visualization graph for model.
      const aSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();

      const aSceneNode = await aSceneNodeFactory.createGraphFromBIMModel(this.bimModel, theRepMask);
      if (!aSceneNode) {
        throw new Error('Failed to create scene graph from the model.');
      }

      this.modelSceneNode.addChildNode(aSceneNode);

      // Update scene to apply changes.
      await this.updateSceneSmoothly(aModelDisplayingProgressScope);

      // Finally move camera to position when the whole model is in sight
      this.viewport.fitAll(this.fitAllMargins);
    }
    finally {
      aModelDisplayingProgressScope.close();
    }
  }

  /**
   * @param {ModelInfo} theModelInfo
   * @param {cadex.ModelData_ExternalDataProvider} dataProvider
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadAndDisplayModel (theModelInfo, dataProvider, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    try {
      await this.clear(new cadex.Base_ProgressScope(theProgressScope, 1));
      await this.loadModel(theModelInfo.filename, dataProvider, new cadex.Base_ProgressScope(theProgressScope, 5));
      await this.displayModel(cadex.ModelData_RepresentationMask.ModelData_RM_Any, new cadex.Base_ProgressScope(theProgressScope));
    }
    catch (theErr) {
      console.error(`Unable to load and display model "${theModelInfo.modelName}"`, theErr);
      alert(`Unable to load and display model "${theModelInfo.modelName}" [${/** @type {Error} */(theErr).message}]`);
    }
    finally {
      theProgressScope.close();
    }
  }

  /**
   * @param {ModelInfo} theModelInfo
   * @param {cadex.ModelData_ExternalDataProvider} dataProvider
   * @param {cadex.Base_ProgressScope} theProgressScope
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadAndDisplayBIMModel (theModelInfo, dataProvider, theProgressScope) {
    if (this.disposed || theProgressScope.owner.wasCanceled()) {
      return;
    }
    try {
      await this.clear(new cadex.Base_ProgressScope(theProgressScope, 1));
      await this.loadBIMModel(theModelInfo.filename, dataProvider, new cadex.Base_ProgressScope(theProgressScope, 5));
      await this.displayBIMModel(cadex.ModelData_RepresentationMask.ModelData_RM_Any, new cadex.Base_ProgressScope(theProgressScope));
    }
    catch (theErr) {
      console.error(`Unable to load and display model "${theModelInfo.modelName}"`, theErr);
      alert(`Unable to load and display model "${theModelInfo.modelName}" [${/** @type {Error} */(theErr).message}]`);
    }
    finally {
      theProgressScope.close();
    }
  }

  /**
   * Periodically moves the camera to position when the whole model is in sight (for better user UX)
   * @param {cadex.Base_ProgressScope} [theProgressScope]
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async updateSceneSmoothly (theProgressScope) {
    if (this.disposed || theProgressScope?.owner.wasCanceled()) {
      return;
    }
    // Fit all camera ~3 times per second
    let aLastBBoxChangedTime = 0;
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const onSceneBBoxChanged = () => {
      const aCurrentTime = new Date().getTime();
      if (aCurrentTime - aLastBBoxChangedTime > 300) {
        aLastBBoxChangedTime = aCurrentTime;
        this.viewport.fitAll(this.fitAllMargins);
      }
    };
    this.scene.addEventListener('boundingBoxChanged', onSceneBBoxChanged);

    try {
      // Update scene to apply changes.
      await this.scene.update(theProgressScope);
    }
    finally {
      this.scene.removeEventListener('boundingBoxChanged', onSceneBBoxChanged);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  dispose () {
    this.model.clear();
    this.bimModel.root = null;
    this.viewport.dispose();
    this.scene.dispose();
    this.disposed = true;
  }
}
