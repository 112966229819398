import cadex from '@cadexchanger/web-toolkit';
import { BaseViewer } from 'components/common/base-viewer.mjs';
import { ViewerTools } from 'components/common/viewer-tools.mjs';
import { BIMPropertiesPanel } from './bim-properties-panel.mjs';
import { BIMStructurePanel } from './bim-structure-panel.mjs';

import 'components/common/common.css';

import './BIMViewer.css';

export interface ModelInfo {
  modelName: string
  filename: string
}

class BIMViewer extends BaseViewer {
  viewerTools: ViewerTools;
  structurePanel: BIMStructurePanel;
  propertiesPanel: BIMPropertiesPanel;

  constructor (container: HTMLElement, viewportConfig?: cadex.ModelPrs_ViewPortConfig) {
    super(container, viewportConfig);

    this.modelSceneNode.selectionMode = cadex.ModelPrs_SelectionMode.Node;
    this.modelSceneNode.displayMode = cadex.ModelPrs_DisplayMode.Shaded;

    this.viewerTools = new ViewerTools({
      viewport: this.viewport,
    });

    // Update model display mode
    this.viewerTools.addEventListener('displayModeChanged', async () => {
      this.modelSceneNode.displayMode = this.viewerTools.displayMode;
      await this.scene.update();
    });

    this.viewerTools.addEventListener('themeChanged', () => {
      document.documentElement.dataset.theme = this.viewerTools.theme;
    });

    // Create structure panel
    const structurePanelHTMLElement = document.createElement('div');
    this.container.appendChild(structurePanelHTMLElement);

    // Setup structure panel
    this.structurePanel = new BIMStructurePanel({
      scene: this.scene,
      modelSceneNode: this.modelSceneNode,
      domElement: structurePanelHTMLElement,
    });

    // Show/hide structure panel on button click
    this.viewerTools.addEventListener('structureButtonActiveChanged', () => {
      if (this.viewerTools.structureButtonActive) {
        this.structurePanel.show();
      }
      else {
        this.structurePanel.hide();
      }
    });
    // Unselect structure button on panel close button click
    this.structurePanel.addEventListener('hide', () => {
      this.viewerTools.structureButtonActive = false;
    });
    this.viewerTools.structureButtonActive = true;

    // Create properties panel
    const propertiesPanelHTMLElement = document.createElement('div');
    this.container.appendChild(propertiesPanelHTMLElement);

    this.propertiesPanel = new BIMPropertiesPanel({
      domElement: propertiesPanelHTMLElement,
    });

    // Show/hide properties panel on button click
    this.viewerTools.addEventListener('propertiesButtonActiveChanged', () => {
      if (this.viewerTools.propertiesButtonActive) {
        this.propertiesPanel.show();
      }
      else {
        this.propertiesPanel.hide();
      }
    });
    // Unselect properties button on panel close button click
    this.propertiesPanel.addEventListener('hide', () => {
      this.viewerTools.propertiesButtonActive = false;
    });
    this.viewerTools.propertiesButtonActive = true;

    // Load properties of selected model elements.
    this.structurePanel.addEventListener('selectionChanged', async () => {
      await this.propertiesPanel.loadBIMElements(this.structurePanel.selectedBimElements());
    });
  }

  async clear (progressScope: cadex.Base_ProgressScope): Promise<void> {
    this.structurePanel.clear();
    await this.propertiesPanel.clear();
    await super.clear(progressScope);
  }

  async loadAndDisplayModel (modelInfo: ModelInfo, dataLoader: cadex.ModelData_ExternalDataProvider, progressScope: cadex.Base_ProgressScope): Promise<void> {
    const newProgressScope = new cadex.Base_ProgressScope(progressScope);
    try {
      await this.clear(new cadex.Base_ProgressScope(newProgressScope, 1));

      await super.loadBIMModel(modelInfo.filename, dataLoader, progressScope);
      if (this.disposed || progressScope.owner.wasCanceled()) {
        return;
      }

      await this.structurePanel.loadModel(this.bimModel, modelInfo.modelName);

      // Update scene to apply changes.
      await this.updateSceneSmoothly(new cadex.Base_ProgressScope(newProgressScope));

      // Finally move camera to position when the whole model is in sight
      this.viewport.fitAll(5);
    }
    catch (error) {
      console.error(`Unable to load and display model "${modelInfo.modelName}"`, error);
      alert(`Unable to load and display model "${modelInfo.modelName}" [${(error as Error).message}]`);
    }
    finally {
      newProgressScope.close();
    }
  }

  dispose (): void {
    this.viewport.domElement.removeChild(this.viewerTools.domElement);
    this.viewport.domElement.removeChild(this.structurePanel.domElement);
    this.viewport.domElement.removeChild(this.propertiesPanel.domElement);
    super.dispose();
  }
}

export default BIMViewer;
