import { useEffect } from 'react';
import cadex from '@cadexchanger/web-toolkit';

import MCADViewer from 'components/MCADViewer';
import BIMViewer from 'components/BIMViewer';
import DrawingViewer from 'components/DrawingViewer';

type ViewerType = MCADViewer | BIMViewer | DrawingViewer | undefined;

interface ReactWTKViewerProps {
  innerRef: React.RefObject<HTMLDivElement> /* Otherwise, the mobX observer fails with various cases of providing forwardRef (even with mobx-react-lite special methods for forwarding ref). */
  modelName: string
  dataProvider: cadex.ModelData_ExternalDataProvider
  nativeExtension?: 'cdxfb' | 'cdxweb' | 'cdxbimweb'
  isPotentialDrawing?: boolean
}

const ReactWTKViewer: React.FC<ReactWTKViewerProps> = ({
  innerRef,
  modelName,
  dataProvider,
  nativeExtension = 'cdxweb',
  isPotentialDrawing = false,
}) => {
  useEffect(() => {
    const container = innerRef.current || document.createElement('div');

    let webViewer: ViewerType = nativeExtension === 'cdxbimweb'
      ? new BIMViewer(container)
      : new MCADViewer(container);
    const progressStatus = new cadex.Base_ProgressStatus();

    const modelInfo = {
      modelName,
      filename: `scenegraph.${nativeExtension}`,
    };

    void (async () => {
      await webViewer.loadAndDisplayModel(
        modelInfo,
        dataProvider,
        progressStatus.rootScope,
      );
      if (isPotentialDrawing && webViewer.model.drawing && (webViewer.model.drawing.numberOfSheets > 0)) {
        webViewer.dispose();
        webViewer = new DrawingViewer(container);
        await webViewer.loadAndDisplayModel(
          modelInfo,
          dataProvider,
          progressStatus.rootScope,
        );
      }
    })();

    return () => {
      progressStatus.cancel();
      webViewer?.dispose();
    };
  }, [modelName, dataProvider]);

  return <div ref={innerRef}></div>;
};

export default ReactWTKViewer;
