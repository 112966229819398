import { BasePanel } from 'components/common/base-panel.mjs';

import $ from 'jquery';

import cadex from '@cadexchanger/web-toolkit';

/**
 * @typedef DrawingLayerTreeData
 * @property {cadex.ModelData_DrawingLayer} layer
 */

/**
 * @typedef DrawingLayerTreeItem
 * @property {string} text
 * @property {string} type
 * @property {Object} [state]
 * @property {boolean} [state.opened]
 * @property {DrawingLayerTreeData} data
 * @property {Array<DrawingLayerTreeItem>} [children]
 */

export let /** @type {{domElement: HTMLElement,scene: cadex.ModelPrs_Scene,sceneNodesProvider(theLayer: cadex.ModelData_DrawingLayer): cadex.ModelPrs_SceneNode[],title?: string}} */ DrawingLayersPanelConfig;

/**
 * @type {Partial<DrawingLayersPanelConfig>}
 */
export const DrawingLayersPanelDefaultConfig = {
  title: 'Layers',
};

export class DrawingLayersPanel extends BasePanel {
  /**
   * @param {DrawingLayersPanelConfig} theConfig
   */
  constructor (theConfig) {
    const aConfig = /** @type {Required<DrawingLayersPanelConfig>} */(Object.assign({}, DrawingLayersPanelDefaultConfig, theConfig));
    super(aConfig);

    this.scene = theConfig.scene;
    this.sceneNodesProvider = theConfig.sceneNodesProvider;

    this.domElement.classList.add('layers-panel');

    const aJSTreeConfig = {
      core: {
        multiple: true,
        check_callback: true,
        themes: {
          name: null, // 'default',
          dots: false,
          icons: false,
        },
      },
      types: {},
      plugins: ['wholerow', 'checkbox', 'node_customize'],
      node_customize: {
        default: function (/** @type {any} */ el, /** @type {DrawingLayerTreeItem} */ node) {
          const aLayer = node.data.layer;
          if (aLayer) {
            const aLayerColorIcon = $(el).find('.jstree-anchor .jstree-layer-color-icon');
            if (aLayerColorIcon.length === 0) {
              let aBgColor = '';
              const aColor = aLayer.appearance?.genericColor;
              if (aColor) {
                aBgColor = `rgb(${Math.round(aColor.r * 255)}, ${Math.round(aColor.g * 255)}, ${Math.round(aColor.b * 255)})`;
              }
              $(el).find('.jstree-anchor .jstree-icon.jstree-themeicon').after(`<i class="jstree-icon jstree-layer-color-icon" role="presentation"><span class="color-box" style="background:${aBgColor}"></span></i>`);
            }
          }
        },
      },
    };

    $(this._panelBody).jstree(aJSTreeConfig)
      .on('select_node.jstree', (/** @type {any} */ _theEvent, /** @type {{ node: DrawingLayerTreeItem; }} */ theData) => {
        const aLayer = /** @type {DrawingLayerTreeItem} */(theData.node).data?.layer;
        if (aLayer) {
          this.sceneNodesProvider(aLayer).forEach((theNode) => {
            theNode.visibilityMode = cadex.ModelPrs_VisibilityMode.Visible;
          });
          void this.scene.update();
        }
      })
      .on('deselect_node.jstree', (/** @type {any} */ _theEvent, /** @type {{ node: DrawingLayerTreeItem; }} */ theData) => {
        const aLayer = /** @type {DrawingLayerTreeItem} */(theData.node).data?.layer;
        if (aLayer) {
          this.sceneNodesProvider(aLayer).forEach((theNode) => {
            theNode.visibilityMode = cadex.ModelPrs_VisibilityMode.Hidden;
          });
          void this.scene.update();
        }
      });

    this.jstree = $(this._panelBody).jstree(true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clear () {
    const aRootNode = this.jstree.get_node('#');
    this.jstree.delete_node(aRootNode.children);
  }

  /**
   * @param {cadex.ModelData_Drawing} theDrawing
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  loadDrawing (theDrawing) {
    this.clear();

    this.drawing = theDrawing;

    if (this.drawing.numberOfLayers === 0) {
      this.jstree.hide_checkboxes();
      this.jstree.create_node('#', { text: 'There is no drawing layers in the model', data: {} });
      return;
    }

    this.jstree.show_checkboxes();

    for (const aLayer of this.drawing.layers()) {
      const aLayerTreeItemConfig = {
        text: aLayer.name || 'Unnamed layer',
        type: 'drawing-layer',
        data: { layer: aLayer },
        state: { selected: aLayer.isVisible },
      };
      this.jstree.create_node('#', aLayerTreeItemConfig);
    }
  }
}
