import type React from 'react';

import './HeaderSection.scss';

export interface HeaderSectionProps {
  rightSidePanel?: React.ReactNode
}

const HeaderSection: React.FunctionComponent<HeaderSectionProps> = ({ rightSidePanel }) => (
  <header id="overview-section" className="pt-6 pb-8 pt-lg-11 pb-lg-10 header-section">
    <div className="container">
      <div className="row align-items-stretch">
        <div className="d-flex flex-row justify-content-center justify-content-lg-end col-12 col-lg-7 order-lg-last">
          { rightSidePanel }
        </div>
        <div className="d-flex flex-column justify-content-center col-12 col-lg-5 col-xxl-5 mt-6 mt-lg-0 text-justify">
          <h1 className="mb-4 mb-md-6 text-left">
            Free online CAD viewer
          </h1>
          <p>
            View CAD files online for free from any device and right in your browser. Explore data from all the key 3D formats, including native CAD systems (SOLIDWORKS, CATIA, Siemens NX, PTC Creo, Autodesk DWG), neutral (glTF, JT, OBJ, STEP, etc.) and kernel formats (ACIS, Parasolid, Rhino, etc.)
          </p>
          <p>
            See <a href="https://cadexchanger.com/formats/">the list</a> of all 30+ supported formats.
          </p>
        </div>
      </div>
    </div>
  </header>
);

export default HeaderSection;
