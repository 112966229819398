import { htmlToElement } from 'components/common/dom.mjs';
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TreeSceneNode,
} from 'components/common/structure-panel.mjs';
import { PropertiesPanel } from 'components/common/properties-panel.mjs';
import { BasePanel } from 'components/common/base-panel.mjs';

import $ from 'jquery';

import cadex from '@cadexchanger/web-toolkit';

/**
 * @typedef DrawingElementTreeData
 * @property {cadex.ModelData_DrawingView} view
 * @property {cadex.ModelData_DrawingElement} element
 * @property {cadex.ModelPrs_SceneNode & TreeSceneNode} [sceneNode]
 */

/**
 * @typedef DrawingElementTreeItem
 * @property {string} text
 * @property {string} type
 * @property {Object} [state]
 * @property {boolean} [state.opened]
 * @property {DrawingElementTreeData} data
 * @property {Array<DrawingElementTreeData>} [children]
 */

class DrawingViewToTreeConverter extends cadex.ModelData_DrawingElementVisitor {
  /**
   * @param {cadex.ModelData_DrawingView} theView
   * @param {function(cadex.ModelData_DrawingView, cadex.ModelData_DrawingElement): cadex.ModelPrs_SceneNode|undefined} sceneNodeProvider
   */
  constructor (theView, sceneNodeProvider) {
    super();
    this.view = theView;
    this.sceneNodeProvider = sceneNodeProvider;
    /** @type {Array<DrawingElementTreeItem>} */
    this.geomNodes = [];
    /** @type {Array<DrawingElementTreeItem>} */
    this.dimensionNodes = [];
    /** @type {Array<DrawingElementTreeItem>} */
    this.textNodes = [];
    /** @type {Array<DrawingElementTreeItem>} */
    this.hatchNodes = [];
  }

  /**
   * @param {cadex.ModelData_DrawingElement} theElement
   * @param {string} icon
   * @param {string} type
   * @returns {DrawingElementTreeItem}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createTreeItem (theElement, icon, type) {
    const anElementSceneNode = this.sceneNodeProvider(this.view, theElement);
    return {
      text: theElement.name || type,
      type: icon,
      data: {
        view: this.view,
        element: theElement,
        sceneNode: /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(anElementSceneNode),
      },
    };
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingAngularDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingAngularDimension (theElement) {
    this.dimensionNodes.push(this.createTreeItem(theElement, 'drawing-dimension', 'Angular Dimension'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingDiametricDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingDiametricDimension (theElement) {
    this.dimensionNodes.push(this.createTreeItem(theElement, 'drawing-dimension', 'Diametric Dimension'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingLinearDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingLinearDimension (theElement) {
    this.dimensionNodes.push(this.createTreeItem(theElement, 'drawing-dimension', 'Linear Dimension'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingRadialDimension} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingRadialDimension (theElement) {
    this.dimensionNodes.push(this.createTreeItem(theElement, 'drawing-dimension', 'Radial Dimension'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingCurveSet} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingCurveSet (theElement) {
    this.geomNodes.push(this.createTreeItem(theElement, 'drawing-geometry', 'Curve Set'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingPiecewiseContour} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingPiecewiseContour (theElement) {
    this.geomNodes.push(this.createTreeItem(theElement, 'drawing-geometry', 'Piecewise Contour'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingPointSet} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingPointSet (theElement) {
    this.geomNodes.push(this.createTreeItem(theElement, 'drawing-geometry', 'Point Set'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingHatch} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingHatch (theElement) {
    this.hatchNodes.push(this.createTreeItem(theElement, 'drawing-hatch', 'Hatch'));
  }

  /**
   * @override
   * @param {cadex.ModelData_DrawingText} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitDrawingText (theElement) {
    this.textNodes.push(this.createTreeItem(theElement, 'drawing-text', 'Text'));
  }
}

/**
 * @typedef TreeItem
 * @property {string} [text]
 * @property {string} [type]
 * @property {Object} [state]
 * @property {boolean} [state.opened]
 * @property {Object} data
 * @property {cadex.ModelData_SceneGraphElement} [data.element]
 * @property {cadex.ModelPrs_SceneNode} data.sceneNode
 * @property {Record<string, Array<TreeItem>>} [typedChildren]
 * @property {Array<TreeItem>} children
 */

export let /** @type {{scene: cadex.ModelPrs_Scene,viewport: cadex.ModelPrs_ViewPort,modelSceneNode: cadex.ModelPrs_SceneNode,domElement: HTMLElement,sceneNodeProvider(view: cadex.ModelData_DrawingView, element: cadex.ModelData_DrawingElement): cadex.ModelPrs_SceneNode|undefined,title?: string}} */DrawingStructurePanelConfig;

/**
 * @type {Partial<DrawingStructurePanelConfig>}
 */
export const DrawingStructurePanelDefaultConfig = {
  title: 'Structure',
};

export class DrawingStructurePanel extends BasePanel {
  /**
   * @param {DrawingStructurePanelConfig} theConfig
   */
  constructor (theConfig) {
    const aConfig = /** @type {Required<DrawingStructurePanelConfig>} */(Object.assign({}, DrawingStructurePanelDefaultConfig, theConfig));
    super(aConfig);

    this.scene = aConfig.scene;
    this.viewport = aConfig.viewport;
    this.modelSceneNode = aConfig.modelSceneNode;
    this.modelSceneNode.selectionMode = cadex.ModelPrs_SelectionMode.Node;

    /** @private */
    this._sceneNodeProvider = aConfig.sceneNodeProvider;

    this.domElement.classList.add('drawing-structure-panel');

    this._sheetSelect = /** @type {HTMLSelectElement} */(htmlToElement('<select title="Select sheet"></select>'));
    this._sheetSelect.addEventListener('change', () => {
      const aSheet = this.drawing?.sheet(Number(this._sheetSelect.value));
      if (aSheet) {
        void this.loadDrawingSheet(aSheet);
      }
    });
    const aSheetSelector = /** @type {HTMLSelectElement} */(htmlToElement('<div class="drawing-structure-panel__sheet-selector">Sheet:</div>'));
    aSheetSelector.appendChild(this._sheetSelect);
    this._panelBody.appendChild(aSheetSelector);

    const aSheetTree = /** @type {HTMLSelectElement} */(htmlToElement('<div class="drawing-structure-panel__tree"></div>'));
    this._panelBody.appendChild(aSheetTree);

    const aJSTreeConfig = {
      core: {
        multiple: false,
        check_callback: true,
        themes: {
          name: null, // 'default',
          dots: true,
        },
      },
      types: {
        'drawing-sheet': {
          icon: 'icon-drawing-sheet',
        },
        'drawing-view': {
          icon: 'icon-drawing-view',
        },
        'drawing-layer': {
          icon: 'icon-drawing-layer',
        },
        'drawing-geometry': {
          icon: 'icon-drawing-geometry',
        },
        'drawing-dimension': {
          icon: 'icon-drawing-dimension',
        },
        'drawing-hatch': {
          icon: 'icon-drawing-hatch',
        },
        'drawing-text': {
          icon: 'icon-drawing-text',
        },
      },
      plugins: ['wholerow', 'types'],
    };

    // Initialize jsTree library used for visualizing scenegraph structure (see https://www.jstree.com/)
    $(aSheetTree).jstree(aJSTreeConfig)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .on('changed.jstree', (_theEvent, theData) => {
        this._onSelectionChangedByTreeView(theData.selected);
      });

    // Subscribe to scene selection events
    this.scene.selectionManager.addEventListener('selectionChanged', this._onSelectionChangedByScene.bind(this));

    this.jstree = $(aSheetTree).jstree(true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  clear () {
    const aRootNode = this.jstree.get_node('#');
    this.jstree.delete_node(aRootNode.children);
    this.modelSceneNode.removeChildNodes();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectedDrawingElements () {
    const anElements = [];
    for (const aSelectedItem of this.scene.selectionManager.selectedItems()) {
      const aSelectedNode = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(aSelectedItem.node);
      if (aSelectedNode.treeId) {
        const aNode = /** @type {DrawingElementTreeItem} */(this.jstree.get_node(aSelectedNode.treeId));
        if (aNode.data.element && aNode.data.view) {
          anElements.push({ element: aNode.data.element, view: aNode.data.view });
        }
      }
    }
    return anElements;
  }

  /**
   * @param {cadex.ModelData_Drawing} theDrawing
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadDrawing (theDrawing) {
    this.drawing = theDrawing;

    this._sheetSelect.options.length = 0;

    for (let i = 0; i < this.drawing.numberOfSheets; i++) {
      const aSheet = /** @type {cadex.ModelData_DrawingSheet} */(this.drawing.sheet(i));
      const aSheetOption = document.createElement('option');
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      aSheetOption.text = (aSheet.name || 'Unnamed Sheet') + ` (${PropertiesPanel.enumValue(cadex.ModelData_DrawingSheetPaperSizeType, aSheet.paperSize)})`;
      aSheetOption.value = `${i}`;
      this._sheetSelect.options.add(aSheetOption);
    }

    if (this.drawing.numberOfSheets > 0) {
      await this.loadDrawingSheet(/** @type {cadex.ModelData_DrawingSheet} */(this.drawing.sheet(0)));
    }
    else {
      this.jstree.create_node('#', { text: 'There is no drawing sheets is the model', type: 'drawing-sheet', data: {} });
      this.clear();
      await this.scene.update();
    }
  }

  /**
   * @param {cadex.ModelData_DrawingSheet} theSheet
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadDrawingSheet (theSheet) {
    this.clear();

    const aSheetSceneNode = new cadex.ModelPrs_SceneNode();
    this.modelSceneNode.addChildNode(aSheetSceneNode);

    const aSheetItemConfig = {
      text: theSheet.name || 'Unnamed Sheet',
      type: 'drawing-sheet',
      state: {
        opened: true,
      },
      data: {
        sheet: theSheet,
        sceneNode: aSheetSceneNode,
      },
    };
    const aSheetTreeNode = this.jstree.create_node('#', aSheetItemConfig);

    // Emulate drawing sheet paper.
    // if (theSheet.name !== 'Model') {
    //   // Add paper rectangle
    //   const aSheetPaper = new cadex.ModelData_IndexedPolyLineSet();
    //   const aSheetWidth = theSheet.width;
    //   const aSheetHeight = theSheet.height;
    //   aSheetPaper.coords = new Float32Array([
    //     0, 0, -1,
    //     0, aSheetHeight, -1,
    //     aSheetWidth, aSheetHeight, -1,
    //     aSheetWidth, 0, -1,
    //   ]);
    //   aSheetPaper.indexes = new Uint16Array([0, 1, 1, 2, 2, 3, 3, 0]);
    //   const aDrawingSceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
    //   const aPaperNode = aDrawingSceneNodeFactory.createNodeFromPolyVertexSet(aSheetPaper);
    //   aPaperNode.appearance = new cadex.ModelData_Appearance(new cadex.ModelData_ColorObject());
    //   this.modelSceneNode.addChildNode(aPaperNode);

    //   // Emulate shadow of sheet paper: offset is 1% of min sheet side
    //   const aPaperShadowSize = Math.min(aSheetWidth, aSheetHeight) / 100;
    //   const aSheetPaperShadow = new cadex.ModelData_IndexedTriangleSet();
    //   aSheetPaperShadow.coords = new Float32Array([
    //     aPaperShadowSize, 0, -1,
    //     aPaperShadowSize, -aPaperShadowSize, -1,
    //     aSheetWidth + aPaperShadowSize, -aPaperShadowSize, -1,
    //     aSheetWidth, 0, -1,
    //     aSheetWidth, aSheetHeight - aPaperShadowSize, -1,
    //     aSheetWidth + aPaperShadowSize, aSheetHeight - aPaperShadowSize, -1,
    //   ]);
    //   aSheetPaperShadow.indexes = new Uint16Array([
    //     0, 1, 2,
    //     0, 2, 3,
    //     3, 2, 4,
    //     4, 2, 5,
    //   ]);
    //   const aPaperShadowNode = aDrawingSceneNodeFactory.createNodeFromPolyVertexSet(aSheetPaperShadow);
    //   aPaperShadowNode.appearance = new cadex.ModelData_Appearance(cadex.ModelData_ColorObject.fromHex(0x495057));
    //   this.modelSceneNode.addChildNode(aPaperShadowNode);
    // }

    for (const aView of theSheet.views()) {
      const aViewSceneNode = new cadex.ModelPrs_SceneNode();
      aSheetSceneNode.addChildNode(aViewSceneNode);
      const aTrsf = new cadex.ModelData_Transformation();
      aTrsf.setRotationPart(
        aView.position.xDirection.x, aView.position.xDirection.y, 0,
        aView.position.yDirection.x, aView.position.yDirection.y, 0,
        0, 0, 1);
      aTrsf.setTranslationPart(aView.position.location.x, aView.position.location.y, 0);
      aViewSceneNode.transformation = aTrsf;
      this.modelSceneNode.addChildNode(aViewSceneNode);

      const aViewTreeItemConfig = {
        text: aView.name || 'Unnamed View',
        type: 'drawing-view',
        data: {
          view: aView,
          sceneNode: aViewSceneNode,
        },
      };
      const aViewTreeNode = this.jstree.create_node(aSheetTreeNode, aViewTreeItemConfig);

      const aViewToTreeConverter = new DrawingViewToTreeConverter(aView, this._sceneNodeProvider);
      for (const element of aView.elements()) {
        element.accept(aViewToTreeConverter);
      }

      /**
       * @param {Array<DrawingElementTreeItem>} theItems
       * @param {string} theName
       * @param {string} theIcon
       */
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      const addGroup = (theItems, theName, theIcon) => {
        if (theItems.length === 0) {
          return;
        }
        theItems.sort((a, b) => a.text.localeCompare(b.text));
        const aGroupNodeId = this.jstree.create_node(aViewTreeNode, {
          text: theName,
          type: theIcon,
          data: {},
        });
        // Assign tree id for scene node for selection purposes
        theItems.forEach((theElementConfig) => {
          const aElementTreeId = this.jstree.create_node(aGroupNodeId, theElementConfig);
          const aSceneNode = theElementConfig.data.sceneNode;
          if (aSceneNode) {
            aSceneNode.detach(); // detach from previous sheet display node
            aSceneNode.treeId = aElementTreeId;
            aViewSceneNode.addChildNode(aSceneNode);
          }
        });
      };
      addGroup(aViewToTreeConverter.geomNodes, 'Geometries', 'drawing-geometry');
      addGroup(aViewToTreeConverter.dimensionNodes, 'Dimensions', 'drawing-dimension');
      addGroup(aViewToTreeConverter.hatchNodes, 'Hatches', 'drawing-hatch');
      addGroup(aViewToTreeConverter.textNodes, 'Texts', 'drawing-text');

      this.jstree.open_node(aViewTreeNode);
    }

    await this.scene.update();

    // Finally move camera to position when the whole model is in sight
    this.viewport.fitAll(5);
  }

  /**
   * @private
   * @param {cadex.ModelPrs_SelectionChangedEvent} theEvent
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  _onSelectionChangedByScene (theEvent) {
    theEvent.added.forEach(theAdded => {
      const anAddedObject = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(theAdded.node);
      if (anAddedObject.treeId) {
        this.jstree.select_node(anAddedObject.treeId, true);
      }
    });
    theEvent.removed.forEach(theRemoved => {
      const aRemovedObject = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(theRemoved.node);
      if (aRemovedObject.treeId) {
        this.jstree.deselect_node(aRemovedObject.treeId, true);
      }
    });
    // Handle deselectAll on click on empty space on the viewer
    if (this.scene.selectionManager.numberOfSelectedItems === 0) {
      this.jstree.deselect_all(true);
    }
    // Manually notify jsTree plugins about selection change to apply tree styles.
    this.jstree.trigger('changed', {
      action: 'select_by_scene',
      selected: this.jstree.get_selected(),
    });
  }

  /**
   * @private
   * @param {string[]} theNodeIds
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  _onSelectionChangedByTreeView (theNodeIds) {
    this.scene.selectionManager.deselectAll(false);
    theNodeIds.forEach((theNodeId) => {
      const aJstreeNode = this.jstree.get_node(theNodeId);
      this.collectDrawingElementJstreeNodes(aJstreeNode).forEach(thePartJstreeNode => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const aSceneNode = /** @type {cadex.ModelPrs_SceneNode|undefined} */(thePartJstreeNode.data.sceneNode);
        if (aSceneNode) {
          this.scene.selectionManager.selectNode(aSceneNode, /* theBreakSelection */false, /* theDispatchEvent */false);
        }
      });
    });
    this.dispatchEvent({ type: 'selectionChanged' });
  }

  /**
   * @private
   * @param {Object} theJstreeNode
   * @returns {Array<Object>}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  collectDrawingElementJstreeNodes (theJstreeNode) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (theJstreeNode.data.element instanceof cadex.ModelData_DrawingElement) {
      return [theJstreeNode];
    }
    else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return theJstreeNode.children_d.reduce((theNodes, theChildId) => {
        const aChild = this.jstree.get_node(theChildId);
        if (aChild.data.element instanceof cadex.ModelData_DrawingElement) {
          theNodes.push(aChild);
        }
        return theNodes;
      }, []);
    }
  }
}
