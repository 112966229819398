interface SupportedExtensions {
  import: Record<string, string[]>
}

export const supportedExtensions: SupportedExtensions = {
  import: {
    '3DPDF': ['pdf'],
    '3DS': ['3ds'],
    '3DXML': ['3dxml'],
    '3MF': ['3mf'],
    ACIS: ['sat', 'sab'],
    Archive: ['zip'],
    BRep: ['brep'],
    'CAD Exchanger Native': ['cdx', 'xml'],
    CAT: ['catpart', 'catproduct'],
    Collada: ['dae'],
    Creo: ['prt.*', 'asm.*'],
    DWG: ['prc'],
    DXF: ['dxf'],
    FBX: ['fbx'],
    GLTF: ['glb', 'gltf'],
    IFC: ['ifc'],
    IGES: ['igs', 'iges'],
    Inventor: ['iam', 'ipt'],
    JT: ['jt'],
    NX: ['prt'],
    OBJ: ['obj'],
    Parasolid: ['x_t', 'x_b', 'xmt_txt', 'xmt_bin', 'xmp_txt', 'xmp_bin'],
    PLY: ['ply'],
    PRC: ['dwg'],
    Rhino: ['3dm'],
    SLDE: ['par', 'psm', 'asm'],
    SolidWorks: ['sldprt', 'sldasm'],
    STEP: ['stp', 'step'],
    STL: ['stl'],
    U3D: ['u3d'],
    VRML: ['wrl'],
    Revit: ['rvt'],
  },
};

export function getExtension (fileName: string): string {
  const fileParts = fileName.split('.');
  if (fileParts.length < 2) return '';
  const ext = fileParts[fileParts.length - 1];
  if (fileParts.length > 2) {
    if (Number.isNaN(parseInt(ext, 10))) { // real extension
      return ext;
    }
    // Example: fileName.prt.<number>
    return `${fileParts[fileParts.length - 2]}.*`;
  }

  return ext;
}

export default function isExtensionValid (file: File): boolean {
  if (!file.size) {
    return false;
  }

  const sourceExt = getExtension(file.name).toLowerCase();
  if (!sourceExt.length) {
    return false;
  }

  const sourceFormat = Object.keys(supportedExtensions.import).find((formatName: string) => (
    supportedExtensions.import[formatName].includes(sourceExt)
  ));
  if (!sourceFormat) {
    return false;
  }

  return true;
}
