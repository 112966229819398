import type React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';

import UploadFileBtn from 'components/UploadFileBtn';

import { ReactComponent as LogoSVG } from 'img/viewer-navbar/logo.svg';
import { ReactComponent as ShareSVG } from 'img/viewer-navbar/share.svg';
import { ReactComponent as HamburgerSVG } from 'img/viewer-navbar/hamburger.svg';
import { ReactComponent as ArrowBackSVG } from 'img/viewer-navbar/arrow-back.svg';

import css from './ViewerNavbar.module.scss';

interface ViewerNavbarProps {
  innerRef?: React.RefObject<HTMLDivElement>
}

interface ViewerNavbarState {
  isPopoverVisible: boolean
}

const ViewerNavbar: React.FC<ViewerNavbarProps> = ({ innerRef }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState<ViewerNavbarState['isPopoverVisible']>(false);

  const style: React.CSSProperties = {
    visibility: isPopoverVisible ? 'visible' : 'hidden',
  };

  /* To externally handle the hamburger menu close event. */
  useEffect(() => {
    const hideNavBarHamburgerMenu = (): void => {
      setIsPopoverVisible(false);
    };
    document.addEventListener('ov-hide-nav-hamburger-menu', hideNavBarHamburgerMenu);
    return () => {
      document.removeEventListener('ov-hide-nav-hamburger-menu', hideNavBarHamburgerMenu);
    };
  });

  const hamburgerMenu = (
    <section className={css.popoverPanel} style={style}>

      <Link
        to="/"
        className={css.popoverLink}
      >
        <ArrowBackSVG />
        <span>Back to start page</span>
      </Link>

      <div className={css.divider} />

      <UploadFileBtn
        className={`${css.popoverLink} ${css.import}`}
        title="Import"
        showIcon={false}
      />

      <div className={css.divider} />

      <a
        target="_blank"
        rel="noreferrer"
        href="https://cadexchanger.com/contact-us"
        className={css.popoverLink}
      >
        Contact us
      </a>

    </section>
  );

  return (
    <Navbar ref={innerRef} className={`sticky-top navbar navbar-light py-1 py-xxl-3 shadow-sm ${css.navbar}`}>
      <div className="container-fluid justify-content-start align-items-center">
        <div
          role="button"
          tabIndex={0}
          className={`btn btn-white p-2 ml-n2 border-0 ${css.hamburger}`}
          onClick={() => {
            setIsPopoverVisible(!isPopoverVisible);
          }}
        >
          <HamburgerSVG />
          { hamburgerMenu }
        </div>
        <Navbar.Brand className={`ml-4 ${css.brand}`} as={Link} to="/">
          <LogoSVG />
        </Navbar.Brand>
        <button className="d-none d-sm-flex flex-row align-items-center btn btn-primary border-0 ml-auto py-2 disabled" type="button">
          <ShareSVG className="mr-3" />
          <span className={`ml-4 ${css.shareTitle}`}>Share</span>
        </button>
      </div>
    </Navbar>
  );
};

export default observer(ViewerNavbar);
