/**
 * @param {string} theHtml HTML representing a single element
 * @return {HTMLElement}
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function htmlToElement (theHtml) {
  const aTemplate = document.createElement('template');
  theHtml = theHtml.trim(); // Never return a text node of whitespace as the result
  aTemplate.innerHTML = theHtml;
  return /** @type {HTMLElement} */(aTemplate.content.firstChild);
}
