import React from 'react';
import aos from 'aos';

import 'aos/dist/aos.css';

interface AOSProps {
  children?: React.ReactNode
}

const initAOS: React.EffectCallback = () => {
  aos.init({
    duration: 700,
    easing: 'ease-out-quad',
    once: true,
    startEvent: 'load',
  });
  aos.refresh();
};

const AOS: React.FunctionComponent<AOSProps> = ({ children }) => {
  React.useEffect(initAOS, []);

  return (
    <>{children}</>
  );
};

export default AOS;
