import type React from 'react';
import { useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AppStoreContext from 'contexts/AppStoreContext';
import SamplesStoreContext from 'contexts/SamplesStoreContext';

import './SamplesSection.scss';

interface SamplesSectionProps {
  innerRef?: React.RefObject<HTMLDivElement>
  uploadFileBtn?: JSX.Element
}

const SamplesSection: React.FunctionComponent<SamplesSectionProps> = observer(({
  innerRef,
  uploadFileBtn,
}) => {
  const appStore = useContext(AppStoreContext);
  const samplesStore = useContext(SamplesStoreContext);

  const history = useHistory();

  const samplesCarousel = useRef<HTMLDivElement>(null);

  const handleSamplesSectionMouseMove = (event: React.MouseEvent<HTMLElement>): void => {
    if (samplesStore.isMouseDown) {
      samplesStore.setTranslateX(samplesStore.translateX + (event.movementX * 2)); // "* x" is x scrolling speed.
      samplesStore.setIsTranslated(true);
    }
  };

  const handleSamplesSectionMouseDown = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    samplesStore.setIsMouseDown(true);
    samplesStore.setIsTranslated(false);
  };

  const handleSamplesSectionMouseUp = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    samplesStore.setIsMouseDown(false);
  };

  const handleSamplesSectionMouseLeave = (): void => {
    samplesStore.setIsMouseDown(false);
    samplesStore.setIsTranslated(false);
  };

  const handleSampleCardClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    if (!samplesStore.isTranslated) {
      const modelName = event.currentTarget.dataset.modelName as string;
      const sampleExtension = samplesStore.cards.find((card) => card.modelName === modelName)?.extension;
      if (sampleExtension) {
        appStore.setModelFileExtension(sampleExtension);
      }
      else {
        throw new Error('Unknown model file extension');
      }
      history.push(`/samples/${modelName}`);
    }
  };

  useEffect(() => {
    if (samplesCarousel.current) {
      const { clientWidth } = document.body;
      const samplesCarouselWidth = samplesCarousel.current.getBoundingClientRect().width;
      const translateRestriction = (samplesCarouselWidth - clientWidth) / 2 + 40;
      if (
        !(samplesStore.translateX >= translateRestriction) && !(samplesStore.translateX < -translateRestriction)
      ) {
        /* If the block of samples is within the allowed range in width, then it may shift. */
        samplesCarousel.current.style.transform = `translateX(${samplesStore.translateX}px)`;
      }
      else if (samplesStore.translateX >= 0) {
        /* Otherwise, it is limited to the left. */
        samplesStore.setTranslateX(translateRestriction);
      }
      else {
        /* Otherwise, it is limited to the right. */
        samplesStore.setTranslateX(-translateRestriction);
      }
    }
  }, [samplesStore.translateX]);

  useEffect(() => {
    samplesStore.setIsTouch('ontouchstart' in window || !!navigator.maxTouchPoints);
  }, ['ontouchstart' in window, navigator.maxTouchPoints]);

  useEffect(() => {
    /* For not touchable: samplesCarousel.current?.getBoundingClientRect().width. */
    /* If device is touchable then scroll left (centered) samples carousel: */
    if (samplesStore.isTouch) {
      if (samplesCarousel.current) {
        const { scrollWidth } = samplesCarousel.current;
        const scrollLeft = ((scrollWidth / 2) - (samplesCarousel.current?.getBoundingClientRect().width / 2));
        samplesCarousel.current.scroll({ left: scrollLeft });
      }
    }
  }, []);

  const cards = (
    <>
      {
        samplesStore.cards.map((card) => (
          <div
            className="card shadow-light samples-section-card"
            key={card.title}
            data-model-name={card.modelName}
            onClick={handleSampleCardClick}
          >
            <img src={card.img} srcSet={`${card.img}, ${card.imgX2} 2x`} alt={card.imgAlt} className="card-img-top" />
            <div className="card-body p-4 pt-5">
              <h5 className="card-title mb-5">{card.title}</h5>
              <div className="container-fluid p-0">
                <div className="row no-gutters mb-3">
                  <div className="col-5">Format</div>
                  <div className="font-weight-bold text-dark">{card.format}</div>
                </div>
                <div className="row no-gutters">
                  <div className="col-5">Size</div>
                  <div className="font-weight-bold text-dark">{card.size}</div>
                </div>
              </div>
            </div>
          </div>
        ))
      }
    </>
  );

  return (
    <section ref={innerRef} id="samples-section" className="d-flex flex-column align-items-center justify-content-between pt-5 pb-9 pt-xxl-10 samples-section">
      <h2 className="mb-9 mx-4 mx-md-0 text-center samples-section-title">Check out sample CAD files</h2>
      <div
        ref={samplesCarousel}
        data-aos="fade-up"
        className={`samples-section-carousel ${samplesStore.isTouch ? 'w-100' : ''} mb-6`}
        style={samplesStore.isTouch ? { overflowY: 'hidden' } : undefined}
        onMouseMove={handleSamplesSectionMouseMove}
        onMouseDown={handleSamplesSectionMouseDown}
        onMouseUp={handleSamplesSectionMouseUp}
        onMouseLeave={handleSamplesSectionMouseLeave}
      >
        {cards}
      </div>
      {
        uploadFileBtn
      }
    </section>
  );
});

export default SamplesSection;
