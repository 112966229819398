import type React from 'react';

import { LinkWithArrow } from '@cadexchanger/react-component-library';

import WhatUnderTheHoodWEBP from 'img/learn-more/what-under-the-hood.webp';
import WhatUnderTheHoodX2WEBP from 'img/learn-more/what-under-the-hood-x2.webp';

import './LearnMoreSection.scss';

const LearnMoreSection: React.FunctionComponent = () => (
  <>
    <section className="py-8 py-md-10 bg-light learn-more-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 order-lg-last text-center text-lg-right" data-aos="fade-left">
            <img src={WhatUnderTheHoodWEBP} srcSet={`${WhatUnderTheHoodWEBP}, ${WhatUnderTheHoodX2WEBP} 2x`} className="mw-100" alt="Want to know what’s under the hood" />
          </div>
          <div className="col-12 col-lg-5 mt-6 mt-lg-0" data-aos="fade-right">
            <h2 className="text-left mb-5 mr-6 mr-xxl-12">Want to know what’s under the hood?</h2>
            <LinkWithArrow to="https://cadexchanger.com/products/sdk/" className="">
              Learn more
            </LinkWithArrow>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default LearnMoreSection;
