import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  TreeSceneNode,
  StructurePanel,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  StructurePanelConfig,
} from 'components/common/structure-panel.mjs';

import cadex from '@cadexchanger/web-toolkit';

/**
 * @typedef TreeItem
 * @property {string} [text]
 * @property {string} [type]
 * @property {Object} [state]
 * @property {boolean} [state.opened]
 * @property {Object} data
 * @property {cadex.ModelData_BIMElement} [data.element]
 * @property {cadex.ModelPrs_SceneNode} data.sceneNode
 * @property {Record<string, Array<TreeItem>>} [typedChildren]
 * @property {Array<TreeItem>} children
 */

class BIMGeometryStateNotifier extends cadex.ModelPrs_SceneNodeVisitor {
  /**
   * @param {any} theJSTree
   * @param {string} theTreeNodeId
   */
  constructor (theJSTree, theTreeNodeId) {
    super();
    this.jstree = theJSTree;
    this.treeNodeId = theTreeNodeId;
    /** @type {Array<cadex.ModelPrs_Geometry>} */
    this.geometries = [];
  }

  /**
   * @override
   * @param {cadex.ModelPrs_SceneNode} theNode
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitEnter (theNode) {
    const aGeometry = theNode.geometry;
    if (aGeometry) {
      /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(theNode).treeId = this.treeNodeId;
      this.geometries.push(aGeometry);
      aGeometry.addEventListener('stateChanged', () => {
        this.checkTotalState();
      });
    }
    return true;
  }

  /**
   * @override
   * @param {cadex.ModelPrs_SceneNode} _theNode
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitLeave (_theNode) {
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  checkTotalState () {
    const aTotalState = this.geometries.reduce((theTotalState, theGeometry) => {
      // Loading state has max priority
      if (theGeometry.state === cadex.ModelPrs_GeometryState.Loading) {
        return cadex.ModelPrs_GeometryState.Loading;
      }
      if (theGeometry.state === cadex.ModelPrs_GeometryState.Failed) {
        return cadex.ModelPrs_GeometryState.Failed;
      }
      return theTotalState;
    }, cadex.ModelPrs_GeometryState.Completed);

    switch (aTotalState) {
      case cadex.ModelPrs_GeometryState.Loading:
        this.jstree.loading_node(this.treeNodeId);
        break;
      case cadex.ModelPrs_GeometryState.Completed:
        this.jstree.display_node(this.treeNodeId);
        break;
      case cadex.ModelPrs_GeometryState.Failed:
        this.jstree.error_node(this.treeNodeId);
        break;
      default:
        break;
    }
  }
}

class BIMStructureConverter extends cadex.ModelData_BIMElementVisitor {
  /**
   * @param {cadex.ModelData_RepresentationMask} theRepMask
   * @param {cadex.ModelPrs_SceneNode} theParentNode
   */
  constructor (theRepMask, theParentNode) {
    super();
    // Add dummy root node like empty host element
    /** @type {Array<TreeItem>} */
    this.treeItemsStack = [{
      data: {
        sceneNode: theParentNode,
      },
      typedChildren: {},
      children: [],
    }];
    this.sceneNodeFactory = new cadex.ModelPrs_SceneNodeFactory();
    this.repMask = theRepMask;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  currentTreeItem () {
    return this.treeItemsStack[this.treeItemsStack.length - 1];
  }

  /**
   * @param {any} theJsTree
   * @param {string} theParentNode
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  createTree (theJsTree, theParentNode) {
    // Take dummy tree root
    const aDummyRootTreeItem = this.treeItemsStack[0];
    // Process dummy root element as host element to process spaces correctly
    this.visitBIMHostElementLeave();

    /**
     * @param {string} theParentNodeId
     * @param {TreeItem} theTreeItem
     */
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const createNode = (theParentNodeId, theTreeItem) => {
      const aTreeNodeId = theJsTree.create_node(theParentNodeId, {
        text: theTreeItem.text,
        type: theTreeItem.type,
        state: theTreeItem.state,
        data: theTreeItem.data,
      });
      /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(theTreeItem.data.sceneNode).treeId = aTreeNodeId;
      if (theTreeItem.data.element instanceof cadex.ModelData_BIMGeometryElement) {
        const aNotifier = new BIMGeometryStateNotifier(theJsTree, aTreeNodeId);
        theTreeItem.data.sceneNode.accept(aNotifier);
      }
      theTreeItem.children.forEach(theChildTreeData => {
        createNode(aTreeNodeId, theChildTreeData);
      });
    };

    aDummyRootTreeItem.children.forEach((theTreeItem) => {
      createNode(theParentNode, theTreeItem);
    });
    theJsTree.open_node(theParentNode);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMBeam} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitBeam (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Beam');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMColumn} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitColumn (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Column');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMDoor} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitDoor (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Door');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMFurniture} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitFurniture (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Furniture');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMPlate} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitPlate (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Plate');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMRailing} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitRailing (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Railing');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMRoof} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitRoof (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Roof');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSlab} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitSlab (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Slab');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMStair} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitStair (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Stair');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMWall} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitWall (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Wall');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMWindow} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitWindow (theElement) {
    await this.visitBIMGeometryElement(theElement, 'Window');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMCustomGeometryElement} theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitCustomGeometryElement (theElement) {
    const aParentTypedChildren = this.currentTreeItem().typedChildren;
    if (aParentTypedChildren) {
      let aTypedGroup = aParentTypedChildren['Other Element'];
      if (!aTypedGroup) {
        aTypedGroup = [];
        aParentTypedChildren['Other Element'] = aTypedGroup;
      }

      /** @type {TreeItem} */
      const aTreeItem = {
        text: theElement.name || 'Unnamed element',
        type: 'bim-custom-element',
        data: {
          element: theElement,
          sceneNode: await this.sceneNodeFactory.createGraphFromBIMGeometryElement(theElement, this.repMask),
        },
        children: [],
      };

      aTypedGroup.push(aTreeItem);
    }
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMBuilding} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBuildingEnter (theElement) {
    return this.visitBIMHostElementEnter(theElement, 'Building');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMBuilding} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBuildingLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSite} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSiteEnter (theElement) {
    return this.visitBIMHostElementEnter(theElement, 'Site');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSite} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSiteLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMStorey} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitStoreyEnter (theElement) {
    return this.visitBIMHostElementEnter(theElement, 'Storey');
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMStorey} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitStoreyLeave (_theElement) {
    this.visitBIMHostElementLeave(true);
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSpace} theElement
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSpaceEnter (theElement) {
    // Spaces are also groped as Geometry elements

    const aSceneNode = this.sceneNodeFactory.createNodeFromBIMElement(theElement);

    /**
     * @type {TreeItem}
     */
    const aTreeItem = {
      text: theElement.name || 'Unnamed Space',
      type: 'bim-space',
      data: {
        element: theElement,
        sceneNode: aSceneNode,
      },
      typedChildren: {},
      children: [],
    };

    const aParentItem = this.currentTreeItem();

    const aParentTypedChildren = aParentItem.typedChildren;
    if (aParentTypedChildren) {
      let aSpacesGroup = aParentTypedChildren.Space;
      if (!aSpacesGroup) {
        aSpacesGroup = [];
        aParentTypedChildren.Space = aSpacesGroup;
      }
      aSpacesGroup.push(aTreeItem);
    }
    else {
      aParentItem.children.push(aParentItem);
    }

    this.treeItemsStack.push(aTreeItem);

    return true;
  }

  /**
   * @override
   * @param {!cadex.ModelData_BIMSpace} _theElement
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitSpaceLeave (_theElement) {
    this.visitBIMHostElementLeave();
  }

  /**
   * @package
   * @param {!cadex.ModelData_BIMGeometryElement} theElement
   * @param {string} theTypeName
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async visitBIMGeometryElement (theElement, theTypeName) {
    const aParentTypedChildren = this.currentTreeItem().typedChildren;
    if (aParentTypedChildren) {
      let aTypedGroup = aParentTypedChildren[theTypeName];
      if (!aTypedGroup) {
        aTypedGroup = [];
        aParentTypedChildren[theTypeName] = aTypedGroup;
      }

      /** @type {TreeItem} */
      const aTreeItem = {
        text: theElement.name || `Unnamed ${theTypeName}`,
        type: 'bim-' + theTypeName.toLowerCase().replace(' ', '-'),
        data: {
          element: theElement,
          sceneNode: await this.sceneNodeFactory.createGraphFromBIMGeometryElement(theElement, this.repMask),
        },
        children: [],
      };

      aTypedGroup.push(aTreeItem);
    }
  }

  /**
   * @private
   * @param {!cadex.ModelData_BIMHostElement} theElement
   * @param {string} theTypeName
   * @returns {boolean}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBIMHostElementEnter (theElement, theTypeName) {
    const aSceneNode = this.sceneNodeFactory.createNodeFromBIMElement(theElement);

    /**
     * @type {TreeItem}
     */
    const aTreeItem = {
      text: theElement.name || `Unnamed ${theTypeName}`,
      type: 'bim-' + theTypeName.toLowerCase(),
      state: {
        opened: true,
      },
      data: {
        element: theElement,
        sceneNode: aSceneNode,
      },
      typedChildren: {},
      children: [],
    };

    const aParentItem = this.currentTreeItem();

    aParentItem.children.push(aTreeItem);
    this.treeItemsStack.push(aTreeItem);

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  visitBIMHostElementLeave (theElementGroupsFirst = false) {
    const aHostItem = this.currentTreeItem();
    const aHostSceneNode = aHostItem.data.sceneNode;

    const aTypedChildren = aHostItem.typedChildren;
    if (aTypedChildren) {
      const aGroupsTreeNodes = Object.keys(aTypedChildren).map((theGroupName) => {
        const aGroupSceneNode = new cadex.ModelPrs_SceneNode();

        aTypedChildren[theGroupName].forEach((theTreeItem) => {
          aGroupSceneNode.addChildNode(theTreeItem.data.sceneNode);
        });

        /** @type {TreeItem} */
        return {
          text: theGroupName + 's',
          type: 'bim-' + theGroupName.toLowerCase().replace(' ', '-'),
          data: {
            sceneNode: aGroupSceneNode,
          },
          children: aTypedChildren[theGroupName],
        };
      });

      if (theElementGroupsFirst) {
        aHostItem.children.unshift(...aGroupsTreeNodes);
      }
      else {
        aHostItem.children.push(...aGroupsTreeNodes);
      }

      delete aHostItem.typedChildren;
    }

    aHostItem.children.forEach((theChild) => aHostSceneNode.addChildNode(theChild.data.sceneNode));

    this.treeItemsStack.pop();
  }
}

/**
 * @typedef {Omit<StructurePanelConfig, "types">}
 * @type {any}
 */
export let BIMStructurePanelConfig;

/**
 * @type {Partial<BIMStructurePanelConfig>}
 */
export const BIMStructurePanelDefaultConfig = {
  title: 'Structure',
};

export class BIMStructurePanel extends StructurePanel {
  /**
   * @param {BIMStructurePanelConfig} theConfig
   */
  constructor (theConfig) {
    const aConfig = /** @type {Required<StructurePanelConfig>} */(Object.assign({
      types: {
        file: {
          icon: 'icon-file',
        },
        'bim-beam': {
          icon: 'icon-bim-beam',
        },
        'bim-building': {
          icon: 'icon-bim-building',
        },
        'bim-column': {
          icon: 'icon-bim-column',
        },
        'bim-custom-element': {
          icon: 'icon-part',
        },
        'bim-other-element': {
          icon: 'icon-assembly',
        },
        'bim-door': {
          icon: 'icon-bim-door',
        },
        'bim-furniture': {
          icon: 'icon-bim-furniture',
        },
        'bim-plate': {
          icon: 'icon-bim-plate',
        },
        'bim-railing': {
          icon: 'icon-bim-railing',
        },
        'bim-roof': {
          icon: 'icon-bim-roof',
        },
        'bim-site': {
          icon: 'icon-bim-site',
        },
        'bim-slab': {
          icon: 'icon-bim-slab',
        },
        'bim-space': {
          icon: 'icon-bim-space',
        },
        'bim-stair': {
          icon: 'icon-bim-stair',
        },
        'bim-storey': {
          icon: 'icon-bim-storey',
        },
        'bim-wall': {
          icon: 'icon-bim-wall',
        },
        'bim-window': {
          icon: 'icon-bim-window',
        },
      },
    }, BIMStructurePanelDefaultConfig, theConfig));
    super(aConfig);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  selectedBimElements () {
    const aBIMElements = [];
    for (const aSelectedItem of this.scene.selectionManager.selectedItems()) {
      const aSelectedNode = /** @type {cadex.ModelPrs_SceneNode & TreeSceneNode} */(aSelectedItem.node);
      if (aSelectedNode.treeId) {
        const aNode = /** @type {TreeItem} */(this.jstree.get_node(aSelectedNode.treeId));
        if (aNode.data.element) {
          aBIMElements.push(aNode.data.element);
        }
      }
    }
    return aBIMElements;
  }

  /**
   * @param {cadex.ModelData_BIMModel} theBimModel
   * @param {string} theModelName
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  async loadModel (theBimModel, theModelName) {
    // Create root file item
    const aFileNodeId = this.jstree.create_node('#', {
      text: theModelName,
      type: 'file',
      data: {},
    });

    const aVisitor = new BIMStructureConverter(this.representationMask, this.modelSceneNode);
    await theBimModel.accept(aVisitor);

    aVisitor.createTree(this.jstree, aFileNodeId);
  }

  /**
   * @override
   * @protected
   * @param {Object} theJstreeNode
   * @returns {Array<Object>}
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  collectGeometryJstreeNodes (theJstreeNode) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (theJstreeNode.data.element instanceof cadex.ModelData_BIMGeometryElement) {
      return [theJstreeNode];
    }
    else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return theJstreeNode.children_d.reduce((theNodes, theChildId) => {
        const aChild = this.jstree.get_node(theChildId);
        if (aChild.data.element instanceof cadex.ModelData_BIMGeometryElement) {
          theNodes.push(aChild);
        }
        return theNodes;
      }, []);
    }
  }
}
