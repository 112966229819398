import type cadex from '@cadexchanger/web-toolkit';
import JSZip from 'jszip';

export type ModelFilesBuffers = Record<string, ArrayBuffer>;

export const dataProviderForJSZip = (data: ModelFilesBuffers): cadex.ModelData_ExternalDataProvider => {
  const getNativeFile = async (fileName: string, _progressScope: cadex.Base_ProgressScope): Promise<ArrayBuffer> => {
    const compressedFile = data[fileName];
    if (!compressedFile) {
      throw new Error(`Compressed file ${fileName} is absent.`);
    }
    return await Promise.resolve(compressedFile);
  };

  return getNativeFile;
};

type GetCDXFBFilesFromJSZipObject = (zipObject: JSZip) => Promise<ModelFilesBuffers>;

const getCDXFBFilesFromJSZipObject: GetCDXFBFilesFromJSZipObject = async (zipObject) => {
  const zipFilesArray = Object.values(zipObject.files);
  const rootDirIndex = zipFilesArray.findIndex((file) => file.dir);
  if (rootDirIndex === -1) {
    throw new Error('Invalid archive');
  }
  const modelName = `${zipFilesArray[rootDirIndex].name}`;

  /* Get all files in root folder only */
  const files = zipFilesArray.filter((file) => !file.dir && file.name.startsWith(modelName.replace(/\/|\\*$/m, '')));

  const cdxfbFiles = await files.reduce(async (previousPromise, currFile) => {
    const result = await previousPromise;
    const fileName = currFile.name.substring(modelName.length);
    (result as ModelFilesBuffers)[fileName] = await currFile.async('arraybuffer');

    return result;
  }, Promise.resolve({} satisfies ModelFilesBuffers));

  return cdxfbFiles;
};

/* Type from JSZip declaration file. */
type InputFileFormat = string | number[] | Uint8Array | ArrayBuffer | Blob | NodeJS.ReadableStream;

type UnzipCDXFB = (data: InputFileFormat) => Promise<ModelFilesBuffers>;

const unzipCDXFB: UnzipCDXFB = async (data) => {
  try {
    const zipObj = await JSZip.loadAsync(data);
    return await getCDXFBFilesFromJSZipObject(zipObj);
  }
  catch (e) {
    throw new Error((e as Error).message);
  }
};

export default unzipCDXFB;
