import type React from 'react';
import { useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import AppStoreContext from 'contexts/AppStoreContext';

import { isEqualFiles } from 'utils/common';

import './Dropzone.scss';

interface DropzoneProps {
  uploadFileBtn?: JSX.Element
  modelImg?: string
  modelImgX2?: string
  modelImgAlt?: string
  onDrop?: () => void
}

const Dropzone: React.FunctionComponent<DropzoneProps> = observer(({ uploadFileBtn, modelImg, modelImgX2, modelImgAlt, onDrop }) => {
  const appStore = useContext(AppStoreContext);
  const history = useHistory();

  const dropContainer = useRef<HTMLInputElement>(null);

  const preventDefaultAndStopPropagation = (event: Event): void => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(() => {
    const addHighlightListener = (): void => {
      dropContainer?.current?.classList.add('dropzone-dashed-box_highlight');
    };

    const removeHighlightListener = (): void => {
      dropContainer?.current?.classList.remove('dropzone-dashed-box_highlight');
    };

    const setUploadFileListener = (event: DragEvent): void => {
      if (event.dataTransfer) {
        if (isEqualFiles(event.dataTransfer.files[0], appStore.uploadFile)) {
          history.push(`/conversion/${appStore.uploadFileConversionID}`);
        }
        else {
          appStore.setUploadFile(event.dataTransfer.files[0]);
        }
      }
    };

    /* Prevent default drag behaviors. */
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      dropContainer?.current?.addEventListener(eventName, preventDefaultAndStopPropagation, false);
      document.body.addEventListener(eventName, preventDefaultAndStopPropagation, false);
    });

    /* Highlight drop area when item is dragged over it. */
    ['dragenter', 'dragover'].forEach((eventName) => {
      dropContainer?.current?.addEventListener(eventName, addHighlightListener, false);
    });
    /* Unhighlight drop area when item is droped or leave area. */
    ['dragleave', 'drop'].forEach((eventName) => {
      dropContainer?.current?.addEventListener(eventName, removeHighlightListener, false);
    });

    /* Handle dropped file. */
    dropContainer?.current?.addEventListener('drop', onDrop || setUploadFileListener, false);

    return () => {
      /* Remove listeners */
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropContainer?.current?.removeEventListener(eventName, preventDefaultAndStopPropagation, false);
        document.body.removeEventListener(eventName, preventDefaultAndStopPropagation, false);
      });
      ['dragenter', 'dragover'].forEach((eventName) => {
        dropContainer?.current?.removeEventListener(eventName, addHighlightListener, false);
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropContainer?.current?.removeEventListener(eventName, removeHighlightListener, false);
      });
      dropContainer?.current?.removeEventListener('drop', onDrop || setUploadFileListener, false);
    };
  }, []);

  return (
    <div className={`card shadow-light dropzone ${!modelImg ? 'h-100' : ''}`}>
      <div ref={dropContainer} className={`d-flex flex-column align-items-center m-4 px-7 py-4 dropzone-dashed-box ${!modelImg ? 'flex-grow-1 justify-content-between' : ''}`}>
        <p className="card-text m-0 text-dark text-nowrap font-italic font-weight-light">
          Up to <strong className="font-weight-bold">50 MB</strong> file size
        </p>
        {
          modelImg && modelImgX2 &&
            <img src={modelImg} srcSet={`${modelImg}, ${modelImgX2} 2x`} className="img-fluid mt-5 mb-2" alt={modelImgAlt} />
        }
        {
          uploadFileBtn
        }
        <p className="card-text mt-3 text-dark text-nowrap">Or drag and drop it here</p>
      </div>
    </div>
  );
});

export default Dropzone;
