import { createPopper } from '@popperjs/core';
import { htmlToElement } from './dom.mjs';

import './dropdown.css';

/**
 * @typedef {{
 *   menuPlacement: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end' | 'left' | 'left-start' | 'left-end';
 *   menuOffsetSkidding: number;
 *   menuOffsetDistance: number;
 * }}
 * @type {any}
 */
export let DropdownConfig;

export class Dropdown {
  /**
   * @param {HTMLButtonElement} theButton
   * @param {HTMLElement} theMenu
   * @param {DropdownConfig} theConfig
   */
  constructor (theButton, theMenu, theConfig) {
    this.popperConfig = {
      placement: theConfig.menuPlacement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [theConfig.menuOffsetSkidding, theConfig.menuOffsetDistance],
          },
        },
      ],
    };

    this.button = theButton;
    this.button.classList.add('dropdown__toggle');
    this.button.addEventListener('click', () => {
      this.toggle();
    });

    this.menu = theMenu;
    this.menu.classList.add('dropdown__menu', 'dropdown__menu_hidden');

    this.domElement = htmlToElement('<div class="dropdown"></div>');
    this.domElement.appendChild(this.button);
    this.domElement.appendChild(this.menu);

    /** @private */
    this._onClickOutsideMenu = this._onClickOutsideMenu.bind(this);
  }

  /**
   * @private
   * @param {MouseEvent} theEvent
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  _onClickOutsideMenu (theEvent) {
    const aComposedPath = theEvent.composedPath();
    if (!aComposedPath.includes(this.menu) && !aComposedPath.includes(this.button)) {
      this.hide();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  isShown () {
    return !this.menu.classList.contains('dropdown__menu_hidden');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  toggle () {
    if (this.isShown()) {
      this.hide();
    }
    else {
      this.show();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  show () {
    if (this.isShown()) {
      return;
    }
    // Connect menu with button click
    this.popperInstance = createPopper(this.button, this.menu, this.popperConfig);
    this.button.classList.add('dropdown__toggle_active');
    this.menu.classList.remove('dropdown__menu_hidden');
    document.addEventListener('click', this._onClickOutsideMenu);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hide () {
    if (!this.isShown()) {
      return;
    }

    this.button.classList.remove('dropdown__toggle_active');
    this.menu.classList.add('dropdown__menu_hidden');
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
    document.removeEventListener('click', this._onClickOutsideMenu);
  }
}
