import cadex from '@cadexchanger/web-toolkit';
import { htmlToElement } from './dom.mjs';

import './base-panel.css';

export let /** @type {{title: string, domElement: HTMLElement, showCloseButton?: boolean }} */ BasePanelConfig;

/** @type {Partial<BasePanelConfig>} */
export const BasePanelDefaultConfig = {
  showCloseButton: true,
};

export class BasePanel extends cadex.ModelPrs_EventDispatcher {
  /**
   * @param {BasePanelConfig} theConfig
   */
  constructor (theConfig) {
    super();

    const aConfigWithDefaults = /** @type {Required<BasePanelConfig>} */(Object.assign({}, BasePanelDefaultConfig, theConfig));

    this.domElement = theConfig.domElement;
    this.domElement.classList.add('base-panel');

    /** @protected */
    this._panelTitle = htmlToElement(
      `<div class="base-panel__title">
        <h5>${theConfig.title}</h5>
      </div>`);

    if (aConfigWithDefaults.showCloseButton) {
      const aPanelCloseButton = /** @type {HTMLButtonElement} */(htmlToElement(
        `<button type="button" title="Close" class="base-panel__close-button">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4L4 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M4 4L12 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </button>`,
      ));
      aPanelCloseButton.addEventListener('click', () => {
        this.hide();
      });
      this._panelTitle.append(aPanelCloseButton);
    }

    /** @protected */
    this._panelBody = htmlToElement('<div class="base-panel__body"></div>');

    this.domElement.appendChild(this._panelTitle);
    this.domElement.appendChild(this._panelBody);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  get isShown () {
    return !this.domElement.classList.contains('base-panel__menu_hidden');
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  show () {
    this.domElement.classList.remove('base-panel__menu_hidden');
    this.dispatchEvent({ type: 'show' });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  hide () {
    this.domElement.classList.add('base-panel__menu_hidden');
    this.dispatchEvent({ type: 'hide' });
  }
}
