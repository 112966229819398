import axios from 'axios';

const CONVERSION_API_URL = process.env.REACT_APP_CONVERSION_API_URL || 'http://localhost:8000/v1/conversions/';

const delay = async (timeout: number): Promise<void> => {
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

const handleCatch = (e: unknown): void => {
  if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
    throw new Error('Conversion results not found. Results of the conversion requested earlier has been removed from the server. Please upload your model again.');
  }
  else {
    throw new Error('An internal error occurred. Please report the problem via the contact form.');
  }
};

export const sendToConvert = async (file: File): Promise<string> => {
  let conversionID = '';
  try {
    const formData = new FormData();
    formData.append('model', file);

    conversionID = (await axios.post(new URL(CONVERSION_API_URL).href, formData)).data.id;
  }
  catch (e) {
    handleCatch(e);
  }
  return conversionID;
};

export const checkConversionStatus = async (id: string): Promise<void> => {
  try {
    if (!id) {
      throw new Error('Invalid conversion id');
    }

    let conversionStatus = '';
    for (let i = 0; i < 600; i += 1) { // max is ~30min
      // eslint-disable-next-line no-await-in-loop
      conversionStatus = (await axios.get(new URL(id, CONVERSION_API_URL).href)).data.status;

      if (conversionStatus !== 'PROCESSING') {
        break;
      }

      /* Checking status every 3sec. In real app logarithmically increasing timeout should be used. */
      // eslint-disable-next-line no-await-in-loop
      await delay(3000);
    }
    if (conversionStatus === 'PROCESSING') {
      throw new Error('Conversion is too long');
    }
    else if (conversionStatus !== 'SUCCESS') {
      throw new Error(`Conversion error [${conversionStatus}]`);
    }
  }
  catch (e) {
    handleCatch(e);
  }
};

export const getConversionResult = async (id: string): Promise<ArrayBuffer> => {
  let conversionResult;
  try {
    conversionResult = (await axios.get(new URL(`${id}/result`, CONVERSION_API_URL).href, {
      responseType: 'arraybuffer',
    })).data;
  }
  catch (e) {
    handleCatch(e);
  }
  return conversionResult;
};

export const getModelFileExtension = async (id: string): Promise<string> => {
  let modelFileExtension;
  try {
    modelFileExtension = (await axios.get(new URL(`${id}/extension`, CONVERSION_API_URL).href, {
      responseType: 'text',
    })).data;
  }
  catch (e) {
    handleCatch(e);
  }
  return modelFileExtension;
};
